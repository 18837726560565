import * as ko from 'knockout';

import { BaseForm } from '../screens/base_form';
import * as trialsApi from '../api/trials';
import * as mmsApi from '../api/measurement_metas';
import { createWithComponent } from '../utils/ko_utils';
import { Deferred } from '../utils/deferred';
import { TrialDerivedMeasurementMetaData } from '../api/trials';
import { SlugGenerator, slugValidation } from '../ko_bindings/slug_validation';
import { I18nText } from '../i18n_text';
import { app } from '../app';
import i18n from '../i18n';
import { Trial } from '../models/trial';

const template = require('raw-loader!../../templates/components/derived_trait_edit_popup.html').default;

class TrialDerivedMeasurementMeta {
  private slugGenerator: SlugGenerator;

  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  nameSlug = ko.observable('').extend(slugValidation);
  formula = ko.observable('').extend({ required: true, serverError: true });
  unitFormula = ko.observable('').extend({ serverError: true });

  constructor(
    private scheduledVisitId: string,
    private datasetId: string,
    data?: TrialDerivedMeasurementMetaData
  ) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.nameSlug(data.name_slug);
      this.formula(data.formula);
      this.unitFormula(data.unit_formula);
    }

    this.slugGenerator = new SlugGenerator(this.nameJson, null, this.nameSlug, {
      canEdit: true,
      fillIfEmpty: true,
    });
  }

  dispose() {
    this.slugGenerator.dispose();
  }



  toData(): TrialDerivedMeasurementMetaData {
    return {
      scheduled_visit_id: this.scheduledVisitId,
      dataset_id: this.datasetId,
      id: this.id(),
      name_json: this.nameJson(),
      name_slug: this.nameSlug(),
      formula: this.formula(),
      unit_formula: this.unitFormula(),
    };
  }
}

class DerivedTraitEditPopup extends BaseForm<TrialDerivedMeasurementMetaData> {
  private trialId: string;
  private id: string;

  entity = ko.observable<TrialDerivedMeasurementMeta>(null);
  availableSuggestions = ko.observableArray<mmsApi.FormulaSuggestion>();

  isRemoving = ko.observable(false);
  deleteButtonLabelText = ko.computed(() => {
    return this.isRemoving() ? i18n.t('Deleting...')() : i18n.t('Delete')();
  });

  constructor(
    params: {
      trial: Trial;
      scheduledVisitId: string;
      datasetId: string;
      id: string;
      result: Deferred<TrialDerivedMeasurementMetaData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super({ result: params.result });

    this.trialId = params.trial.id();
    this.id = params.id;

    const promise = Promise.all([
      mmsApi.librarySuggestions({ trialId: this.trialId }),
      params.id ? trialsApi.derivedTrait(params.trial.id(), params.id) : undefined,
    ]).then(([availableSuggestions, data]) => {
      this.availableSuggestions(availableSuggestions);
      this.entity(new TrialDerivedMeasurementMeta(params.scheduledVisitId, params.datasetId, data));
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  dispose() {
    this.entity()?.dispose();
  }

  async deleteEntity() {
    if(!this.id) {
      return;
    }

    this.isRemoving(true);
    await trialsApi.deleteTrait(this.trialId, this.id);
    this.isRemoving(false);

    this.result.resolve(null);
    this.close();
  }

  save = async () => {
    if (!this.entity()) {
      return;
    }

    if (this.validateLocal(this.entity)) {
      const data = this.entity().toData();
      const validation = await this.executeSaveRequest(
        trialsApi.saveDerivedTrait(this.trialId, this.id, data)
      );
      this.onRemoteValidation(data, this.entity(), validation);
    } else {
      this.saving(false);
    }
  };
}

const derivedTraitEditPopup = {
  name: 'derived-trait-edit-popup',
  viewModel: createWithComponent(DerivedTraitEditPopup),
  template,
};

ko.components.register(derivedTraitEditPopup.name, derivedTraitEditPopup);

export function openEditDerivedTrait(params: {
  trial: Trial;
  scheduledVisitId: string;
  datasetId: string;
  id: string;
}) {
  return app.formsStackController.push({
    title: i18n.t('Derived trait')(),
    name: derivedTraitEditPopup.name,
    params: {
      result: new Deferred<{}>(),
      ...params,
    },
  });
}
