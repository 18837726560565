import {
  request,
  requestWithValidation,
  requestRemoveEntity,
  ValidationResult,
  RemoveResult,
  ListRequestParams,
  listRequest,
  requestRaw,
} from './request';
import { DimensionData } from './dimensions';
import {
  DatasetDimensionMetaData,
  MeasurementMetaData,
  LimitToDimensionData,
  RCBType,
  PlotListData,
  GeoJSON,
} from './datasets';
import { MeasurementMetaData as MeasurementMetaDataV2 } from '../api/v2/interfaces';
import { CountryData } from './countries';
import { RegionData } from './regions';
import { I18nText } from '../i18n_text';
import { DimensionMetaData } from './dimension_metas';
import { UserData } from './users';
import { DataEntryPermissionData } from './groups';
import { TrialSelectData } from './base_trials';
import { AgroRegionData } from './agro_regions';
import { SeasonData } from './seasons';
import { FileUploadEndpoint } from '../cloud_storage_upload';
import { TPPListData } from './tpps';
import { CropData } from './crops';
import { NameData } from './names';
import { TrialTypeData } from './trial_types';
import { SiteData } from './sites';
import { ScheduledVisitData } from './scheduled_visits';
import { TraitActionData } from './trait_actions';

export type EditMode = 'library' | 'manual';

export interface WarningMessageType {
  type: WARNING_MESSAGES_TYPES;
  message: string;
}

export enum WARNING_MESSAGES_TYPES {
  PERIODIC_VISIT = 'periodic_visit',
  TRAITS_WRONG_CROP = 'traits_wrong_crop',
  TPP_TRAITS_NOT_IN_TRIAL = 'tpp_trait_not_in_trial',
  DOMINANT_VARIETY_NOT_SELECTED = 'dominant_variety_not_selected',
  DOMINANT_VARIETY_NO_CONTROL_SET = 'dominant_variety_no_control_set',
  TPP_WRONG_TEST_SUBJECT = 'tpp_wrong_test_subject',
}

export interface TreatmentFactorDimensionData {
  id: string;
  name: string;
  dimension_meta_id: number;
}

export interface TreatmentFactorData {
  id: number;
  dimension: TreatmentFactorDimensionData;
}

export interface TreatmentData {
  id: number;
  name_json: I18nText;
  order: number;
  control: boolean;
  disabled: boolean;
  factors: TreatmentFactorData[];
}

export interface TrialWizardData {
  edit_mode: EditMode;
  trial: TrialData;
  test_subjects: DatasetDimensionMetaData[];
  treatments?: TreatmentData[];
  custom_plot_numbers: boolean;
  custom_plot_position: boolean;
  sp_main_dm_id: string;
  al_block_size: number | string;
  al_replications_per_row: number | string;
  al_blocks_per_replication_row: number | string;
  al_plots_per_block_row: number | string;
  customer_dimensions: DimensionData[];
  sp_rep_blocks_per_row: number;
  manual_allow_create_dimension: boolean;
  sites: LimitToDimensionData[];
  can_add_sites: boolean;
  replications: number;
  save_plot_guides: boolean; // write-only
  plot_guides: PlotGuidesData;
  save_plots: boolean; // write-only
  plots: PlotListData;
  datasets: DatasetWizardData[];
  scheduled_visits: ScheduledVisitData[];
  traits: TrialTraitData[];
  scheduled_visits_days: ScheduledVisitDaysData[];
  copy_dashboard_from_id: string; // never sent from the server, only set on the client or sent to the server
  image_resolution_width: number; // library-only
  image_resolution_height: number; // library-only
  exclude_from_generation_combinations_of_control_and_non_control_test_subjects: boolean;
  was_created_using_treatments_feature: boolean;
  trait_actions: TraitActionData[];
  is_dirty: boolean; // write-only. Not used by the server, but used by the client to detect changes.
}

export interface TrialTraitData {
  mm_id: string;
  mm_template_id?: string; // read-only
  order: number;
}

export interface ScheduledVisitDaysData {
  sv_id: string;
  sv_template_id?: string; // read-only
  days_offset: number;
  days_after_planting: number;
  disabled?: boolean;
}

export interface DatasetWizardData {
  id?: string;
  // set on the client and write-only to the server,
  // used when copying from a template to map the datasets referenced by custom export
  // to newly saved datasets (which have null id)
  copied_from_id: string;
  name_json: I18nText;
  image_resolution_width: number;
  image_resolution_height: number;
  order: number;
  skip_dimension_selection: boolean;
  force_site_selection: boolean;
  contains_management_data: boolean;
  can_add_fact: boolean;
  name_slug: string;
  is_master_dataset: boolean; // write-only
  measurement_dataset_dimension_metas: DatasetDimensionMetaData[];
  measurement_metas: MeasurementMetaData[];
  required_dataset_dimension_metas: DatasetDimensionMetaData[];
  excluded_plot_dm_ids: string[];
}

export type StartingCorner = 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right';
export type WalkOrder =
  | 'left_right'
  | 'top_down'
  | 'zig_zag_horizontal'
  | 'zig_zag_vertical'
  | '2rows_horizontal';
export type PlotNaming =
  | 'default'
  | 'walking_order'
  | 'coordinates'
  | 'sequential_in_replication'
  | 'repetition_and_order';
export type RangeDirection = 'top_down' | 'bottom_up';

export interface TrialData {
  id?: string;
  name_json: I18nText;
  state: string;
  name_slug: string;
  plot_naming: PlotNaming; // returned only by retrieve()
  range_direction: RangeDirection; // returned only by retrieve()
  plot_design: string; // returned only by retrieve()
  separate_test_subjects: boolean; // returned only by retrieve()
  rcb_type: RCBType; // returned only by retrieve()
  row_length: number | string; // returned only by retrieve()
  rcb_block_width: number | string; // returned only by retrieve()
  rcb_blocks_per_row: number | string; // returned only by retrieve()
  starting_corner: StartingCorner; // returned only by retrieve()
  walk_order: WalkOrder; // returned only by retrieve()
  randomization_seed: number; // returned only by retrieve()
  description_json: I18nText;
  trial_type: TrialTypeData;
  crop: CropData;
  cultivation_type: DimensionData;
  plants: number;
  field_preparation_type: DimensionData;
  space_between_rows: number;
  space_inside_rows: number;
  square_meters_per_plot: number;
  rows_per_plot: number;
  border: string;
  field_selection: string;
  plot_length: number;
  plot_width: number;
  created?: string; // read-only
  irrigation_type: DimensionData;
  scheduled_planting_date: string;
  country: CountryData;
  region: RegionData;
  agro_region: AgroRegionData;
  season: SeasonData; // returned only by retrieve()
  customers: NameData[]; // returned only by retrieve()
  protocol: string; // returned only by retrieve()
  is_commercial: boolean; // returned only by retrieve()
  owners: UserData[];
  is_legacy_trial?: boolean; // read-only
  enable_bidir_sync: boolean;
  disable_pictures_from_gallery: boolean;
  was_created_using_treatments_feature: boolean;
  required_location_accuracy: number;
  warn_location_accuracy: boolean;
  template: boolean;
  created_from_template_id?: string; // returned only by retrieve()
  tpp?: TPPListData; // returned only by retrieve()
  scheduled_visits?: ScheduledVisitData[]; // returned only by retrieve()
  traits?: MeasurementMetaDataV2[]; // returned only by retrieve()
  sites?: DimensionData[]; // returned only by retrieve()
  project?: NameData; // returned only by retrieve()
  partner: NameData; // returned only by retrieve()
  last_timestamp?: string; // returned only by list()
  last_staff?: string; // returned only by list()
  measurements_count?: number; // returned only by list() or retrieve()
  measurements_to_collect?: number; // returned only by list()
  data_collection_status?: 'done' | 'in_progress' | 'late' | 'calculating'; // returned only by list()
  measurements_to_collect_by_today?: number; // returned only by list()
  optional_measurements_count?: number; // returned only by list()
  modified?: string; // returned only by list()
  assigned?: boolean; // returned only by list()
  edit_mode?: EditMode; // read-only
  gate_innovation_id?: number; // sfsa gate-linked trials only
  gate_innovation_name?: string; // sfsa gate-linked trials only
  enforce_reason_for_editing_observations: boolean;
}

export interface TrialListFilters {
  search?: string;
  crop_ids?: string[];
  country_ids?: string[];
  region_ids?: string[];
  user_ids?: string[];
  customer_ids?: string[];
  partner_ids?: string[];
  show_archived?: string;
  trial_type_ids?: string[];
  states?: string[];
  sort_by: string;
  sort_direction?: string;
  template: boolean;
}

export interface TrialListRequestParams extends ListRequestParams, TrialListFilters {}

export interface PlotsByRange {
  range: number;
  plots: PrintPlotData[];
}
export interface PrintPlotSiteData {
  name: string;
  plots_by_range: PlotsByRange[];
}

export interface PrintPlotsBySiteData {
  site: PrintPlotSiteData;
}
export interface PrintPlotData {
  id?: string;
  name: string;
  number: number;
  column_position: number;
  range_position: number;
  site_name?: string;
  img?: string;
}
export interface PrintPlotsBySubjectData {
  subject: string;
  subject_name: string;
  anonymized_subject_code: string;
  plots: PrintPlotData[];
}

export interface PrintPlotsData {
  trial: { id: number; name: string; plants: number | null };
  by_site: PrintPlotsBySiteData[];
  by_subject: PrintPlotsBySubjectData[];
}

export interface FieldBookData {
  trial_name: string;
  trial_sites: { id: string; name: string }[];
  datasets: {
    id: string;
    name: string;
    observations: {
      name: string;
      help_text: string;
      help_pictures: {
        url: string;
        description: string;
      }[];
      type: string;
      choices: string[];
    }[];
  }[];
  data_points: {
    [site_id: string]: {
      [dataset_id: string]: {
        title: string;
        description: string;
      };
    };
  };
}

export interface AssignTrialData {
  trial: TrialSelectData;
  data_entry_permissions: DataEntryPermissionData[];
}

export interface StaffSitePermissionData {
  site: SiteData;
  users: UserData[];
}

export interface StaffPermissionData {
  trial_name?: I18nText; // read-only
  plot_count_per_site?: PlotCountPerSite[]; // read-only
  sites: StaffSitePermissionData[];
  notify_users: UserData[]; // write-only
}

export function list(params: TrialListRequestParams): Promise<TrialData[]> {
  return listRequest('/api/trials/', params);
}

export function listForSelect(
  params: {
    template: boolean;
    crop_ids: string[];
    trial_type_ids: string[];
    include_plot_count?: boolean;
  } & ListRequestParams
): Promise<TrialSelectData[]> {
  return listRequest('/api/trials/for_select/', params);
}

export function trialsUsingLibrary(mmId: string): Promise<{ id: string; name: string; state: string }[]> {
  return request('GET', `/api/trials/${mmId}/using_library/`);
}

export interface TrialSiteLocationData {
  site_id: string;
  site_name_json: I18nText;
  trials: {
    name_json: I18nText;
    crop_name_json: I18nText;
  }[];
  location: number[];
  site_area: GeoJSON;
}

export function siteLocations(
  params: TrialListRequestParams & { tpp_id?: string }
): Promise<TrialSiteLocationData[]> {
  return listRequest('/api/trials/site_locations/', params);
}

export interface WeatherChartData {
  type: 'line' | 'bar';
  title: string;
  y_title: string;
  y_title2: string;
  x_title: string;
  x_labels: string[];
  time_unit: 'day' | 'month';
  data: WeatherData;
  planting_date: string;
}

export type WeatherData = {
  type: 'line' | 'bar';
  label: string;
  color?: string;
  second_axis?: boolean;
  data: number[];
}[];

export function weather(trialId: string): Promise<WeatherChartData[]> {
  return request('GET', '/api/trials/' + trialId + '/site_weather/');
}

export function exportWeather(trialId: string): Promise<Blob> {
  return requestRaw('GET', `/api/trials/${trialId}/export_weather/`);
}

export function retrieve(id: string): Promise<TrialData> {
  return request('GET', '/api/trials/' + id + '/');
}

export function retrieveDefaultTemplate(): Promise<TrialSelectData> {
  return request('GET', '/api/trials/default_template/');
}

export function retrieveWizard(id: string, cropId?: string): Promise<TrialWizardData> {
  let url = '/api/trials/' + id + '/wizard/';
  if (cropId) {
    url += '?crop_id=' + cropId;
  }
  return request('GET', url);
}

export interface PlotCountPerSite {
  site_id: string;
  site_name: string;
  plot_count: number;
}

export interface PlotCountData {
  plot_count_per_site: PlotCountPerSite[];
}

export function saveWizard(data: TrialWizardData): Promise<ValidationResult<PlotCountData>> {
  let endpoint = '/api/trials/create_wizard/';
  let method = 'POST';
  if (data.trial.id) {
    endpoint = `/api/trials/${data.trial.id}/update_wizard/`;
    method = 'PUT';
  }

  return requestWithValidation<PlotCountData>(method, endpoint, data);
}

export function makeDraft(id: string): Promise<{}> {
  return request('POST', `/api/trials/${id}/make_draft/`);
}

export function activate(id: string): Promise<{}> {
  return request('POST', `/api/trials/${id}/activate/`);
}

export function setCompleted(id: string): Promise<{}> {
  return request('POST', `/api/trials/${id}/set_completed/`);
}

export function setDataValidated(id: string): Promise<{}> {
  return request('POST', `/api/trials/${id}/set_data_validated/`);
}

export function archive(id: string): Promise<{}> {
  return request('POST', `/api/trials/${id}/archive/`);
}

export function switchToManualMode(id: string): Promise<{}> {
  return request('POST', `/api/trials/${id}/switch_to_manual_mode/`);
}

export function remove(id: string): Promise<RemoveResult> {
  return requestRemoveEntity(`/api/trials/${id}/`);
}

export interface TrialCopyData {
  name_json: I18nText;
  name_slug: string;
  template: boolean;
}

export function copy(id: string, data: TrialCopyData): Promise<ValidationResult> {
  return requestWithValidation('POST', `/api/trials/${id}/copy/`, data);
}

export function makeTemplate(id: string): Promise<{}> {
  return request('POST', `/api/trials/${id}/make_template/`);
}

export function retrievePrintPlots(id: string): Promise<PrintPlotsData> {
  return request('GET', `/api/trials/${id}/print_plots/`);
}

export function retrieveFieldBook(id: string): Promise<FieldBookData> {
  return request('GET', `/api/trials/${id}/field_book/`);
}

export function listDataEntryPermDms(trialId: string): Promise<DimensionMetaData[]> {
  return request('GET', `/api/trials/${trialId}/data_entry_perm_dms/`);
}

export function downloadImportTemplate(
  trialId: string,
  language: string,
  dataLanguage?: string
): Promise<Blob> {
  return dataLanguage
    ? requestRaw(
        'GET',
        `/api/trials/${trialId}/import_template/?language=${language}&dataLanguage=${dataLanguage}`
      )
    : requestRaw('GET', `/api/trials/${trialId}/import_template/?language=${language}`);
}

export interface ImportUpdateOptionsData {
  mms: { id: string; name: string }[];
}

export function getImportUpdateOptions(trialId: string): Promise<ImportUpdateOptionsData> {
  return request('GET', `/api/trials/${trialId}/import_update_options/`);
}

export function downloadImportUpdateTemplate(trialId: string, mmId: string): Promise<Blob> {
  return requestRaw('GET', `/api/trials/${trialId}/import_update_template/?mm_id=${mmId}`);
}

export function getMeasurementImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
  return request('POST', '/api/trials/measurement_image_upload_url/', {
    content_type: contentType,
  });
}

export function retrieveAssigned(trialId: string): Promise<AssignTrialData> {
  return request('GET', `/api/trials/${trialId}/assigned/`);
}

export function saveAssigned(data: AssignTrialData): Promise<ValidationResult> {
  return requestWithValidation('POST', `/api/trials/${data.trial.id}/assign/`, data);
}

export function retrieveAssignStaff(trialId: string): Promise<StaffPermissionData> {
  return request('GET', `/api/trials/${trialId}/assigned_staff/`);
}

export function saveAssignedStaff(trialId: string, data: StaffPermissionData): Promise<ValidationResult> {
  return requestWithValidation('POST', `/api/trials/${trialId}/assign_staff/`, data);
}

export interface TrialActivitySummaryData {
  total: number;
  inactive: number;
}

export function activitySummary(): Promise<TrialActivitySummaryData> {
  return request('GET', '/api/trials/activity_summary/');
}

export interface TrialActivityStatusData {
  id: string;
  name_json: I18nText;
  last_timestamp: string;
}

export function listActivityStatus(params: ListRequestParams): Promise<TrialActivityStatusData[]> {
  return listRequest('/api/trials/activity_status/', params);
}

export interface SiteActivityStatusData {
  name_json: I18nText;
  collected: number;
  to_collect: number;
  last_timestamp: string;
  staff: string[];
}

export function listSiteActivityStatus(trialId: string): Promise<SiteActivityStatusData[]> {
  return request('GET', `/api/trials/${trialId}/site_activity_status/`);
}

export interface WeatherSiteStatusData {
  site_id: string;
  site_name: string;
  from_day: string;
  to_day: string;
  status:
    | 'collecting'
    | 'not_active'
    | 'quota_exceeded'
    | 'no_dm_attr'
    | 'no_dim_attr'
    | 'weather_disabled'
    | 'unknown';
}

export function listWeatherSiteStatus(trialId: string): Promise<WeatherSiteStatusData[]> {
  return request('GET', `/api/trials/${trialId}/status_for_sites/`);
}

export interface WeatherRequiredActionsData {
  site_defines_location: boolean;
  sites_without_location: string[];
}

export function weatherRequiredActions(trialId: string): Promise<WeatherRequiredActionsData> {
  return request('GET', `/api/trials/${trialId}/weather_required_actions/`);
}

export function countRecordsToReview(): Promise<{ count: number }> {
  return request('GET', '/api/trials/count_records_to_review/');
}

export function exportPlots(trialId: string): Promise<Blob> {
  return requestRaw('GET', `/api/trials/${trialId}/export_plots/`);
}

// a partial type, the client only needs to modify ids sent from the server,
// and never creates new instances
export type PlotGuidesData = {
  plot_guides: { id: string[] };
};

export interface CustomLayoutParams {
  dm_ids: string[];
  control_dim_ids: string[];
  existing: PlotGuidesData;
  trial_id: string | null;
}

export function downloadCustomLayoutImportTemplate(data: CustomLayoutParams): Promise<Blob> {
  return requestRaw('POST', '/api/trials/custom_layout_import_template/', data);
}

export interface CustomLayoutData {
  ddm_dimensions: LimitToDimensionData[][];
  site_ids: string[];
  replications: number;
  plot_guides: PlotGuidesData;
  plot_list_data: PlotListData;
  treatments: TreatmentData[];
}

export interface TrialDerivedMeasurementMetaData {
  dataset_id: string;
  scheduled_visit_id: string;
  id: string;
  name_json: I18nText;
  name_slug: string;
  formula: string;
  unit_formula: string;
}

export function derivedTrait(trialId: string, mmId: string): Promise<TrialDerivedMeasurementMetaData> {
  return request('GET', `/api/trials/${trialId}/derived_trait/?id=${mmId}`);
}

export function saveDerivedTrait(
  trialId: string,
  mmId: string,
  data: TrialDerivedMeasurementMetaData
): Promise<ValidationResult> {
  return requestWithValidation('POST', `/api/trials/${trialId}/save_derived_trait/?id=${mmId}`, data);
}

export function getTrialSites(trialId: string): Promise<any> {
  return request('GET', `/api/trials/${trialId}/sites`);
}

export function deleteTrait(trialId: string, traitId: string): Promise<{}> {
  return request('DELETE', `/api/v2/trials/${trialId}/traits/${traitId}/`, {}, { injectTenant: true, version: 2 });
}
