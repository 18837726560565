import { requestAnyWithValidation, ValidationResult, request, requestWithValidation } from './request';
import { TenantData } from './base_tenants';
import { TrialSelectData } from './base_trials';
import { FileUploadEndpoint } from '../cloud_storage_upload';

interface TenantValidationResult extends ValidationResult {
  tenantData: TenantData;
}

interface SignUpData {
  name: string;
  admin_email: string;
  admin_name: string;
  company_name: string;
  role: string;
  phone_number: string;
}

export function save(data: SignUpData): Promise<TenantValidationResult> {
  return requestAnyWithValidation('POST', '/api/tenants/', data).then((val) => {
    if (val.status == 200) {
      return {
        isValid: true,
        entityId: val.result.id,
        tenantData: val.result,
        errors: {},
      };
    } else {
      return {
        isValid: false,
        entityId: null,
        tenantData: null,
        errors: val.result,
      };
    }
  });
}

export interface TenantOptionsData {
  country_and_region_mandatory_for_site: boolean;
  default_trial_template: TrialSelectData;
  allow_password_login: boolean;
  full_access_with_empty_permissions: boolean;
  selective_sync_option: boolean;
  admin_only_data_editing_mode: boolean;
  api_key?: string; // read-only, returned only for admins
  logo_file_name: string | null;
  logo_public_url?: string | null; // read-only
  min_required_app_version_id: number | null;
  grace_period_end_date: string | null;
  web_data_entry: boolean;
  // read-only
  app_versions?: { id: number; title: string }[];
}

export function options(): Promise<TenantOptionsData> {
  return request('GET', '/api/options/');
}

export function saveOptions(data: TenantOptionsData): Promise<ValidationResult> {
  return requestWithValidation('POST', '/api/options/', data);
}

export function resetAPIKey(): Promise<{ api_key: string }> {
  return request('POST', '/api/options/reset_api_key/');
}

export function getTenantLogoUploadURL(contentType: string): Promise<FileUploadEndpoint> {
  return requestAnyWithValidation('POST', '/api/options/logo_upload_url/', {
    content_type: contentType,
  }).then((response) => {
    if (response.status !== 200) {
      return Promise.reject();
    }
    return response.result;
  });
}
