import * as ko from 'knockout';
import i18n from '../i18n';

import { parseDate, parseDateTime, serializeDate, serializeNumber } from '../api/serialization';
import {
  TrialWizardData,
  DatasetWizardData,
  TrialData,
  WalkOrder,
  StartingCorner,
  PlotNaming,
  CustomLayoutData,
  PlotGuidesData,
  EditMode,
  TrialTraitData,
  ScheduledVisitDaysData,
  RangeDirection,
  WarningMessageType,
  WARNING_MESSAGES_TYPES,
} from '../api/trials';
import { DimensionData, listForDMS } from '../api/dimensions';
import { CountryData } from '../api/countries';
import { RegionData } from '../api/regions';
import * as datasetsApi from '../api/datasets';
import * as sitesApi from '../api/sites';
import * as dimensionMetasApi from '../api/dimension_metas';

import { DimensionMeta } from './dimension_meta';
import { DatasetDimensionMeta, LimitToDimension } from './dataset_dimension_meta';
import { Treatment } from './treatment';
import { TraitAction } from './trait_action';
import { MeasurementMeta } from './measurement_meta';
import { OrderedEntities } from './helpers/ordered_entities';
import { DatasetDimensionMetasEditConfiguration } from '../components/dataset_dimension_metas_edit';
import { slugValidation, SlugGenerator, generateSlug } from '../ko_bindings/slug_validation';
import { confirmDialog } from '../components/confirm_dialog';
import { I18nText, asI18nText, translate } from '../i18n_text';
import { UserData } from '../api/users';
import { SITE_SLUG } from '../api/dimension_metas';
import { AgroRegionData } from '../api/agro_regions';
import { SeasonData } from '../api/seasons';
import { allowViewLimitToDimensionNameAndAttributes, canEditMMLibrary, canEditTrial } from '../permissions';
import { TPPListData } from '../api/tpps';
import { CropData } from '../api/crops';
import { NameData } from '../api/names';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import {
  indexOf,
  emptyToNull,
  groupBy,
  accList,
  INTEGER_VALIDATION_RULES,
  tryFormatDateShort,
  waitForElement,
} from '../utils';
import { FormNestedEntitiesConfiguration } from '../components/form_nested_entities';
import { TrialTypeData } from '../api/trial_types';
import { PlotsEditModel } from '../components/trial_wizard/plots_edit/plots_edit_model';
import { session } from '../session';
import { ListRequestParams } from '../api/request';
import {
  listTrialScheduledVisitsPreviews,
  ScheduledVisitData,
  ScheduledVisitPreviewData,
  ScheduledVisitTraitData,
} from '../api/scheduled_visits';
import { ScheduledVisitBase } from './scheduled_visit';
import { typeName } from '../screens/measurement_meta_library';
import { SelectedTraitData, listDependencies, listTrialLibrary } from '../api/measurement_metas';
import { swap } from '../utils/ko_utils';
import { openTrialTraitEdit } from '../components/trial_wizard/trial_trait_details';
import { MeasurementMetaData } from '../api/v2/interfaces';
import { chunk } from 'lodash';
import { TrialState, getTrialStateLabel } from './TrialState';
import * as dragula from 'dragula';

export function getLocationAccuracies() {
  return [
    { value: '-1', title: i18n.t('No minimum accuracy')() },
    { value: '1000', title: i18n.t('Up to 1000 meters')() },
    { value: '100', title: i18n.t('Up to 100 meters')() },
    { value: '20', title: i18n.t('Up to 20 meters')() },
    { value: '10', title: i18n.t('Up to 10 meters')() },
    { value: '5', title: i18n.t('Up to 5 meters')() },
  ];
}

export function getTrialEditModes(): { value: EditMode; title: string }[] {
  return [
    { value: 'manual', title: i18n.t('Manual')() },
    { value: 'library', title: i18n.t('Library')() },
  ];
}

export const CUSTOM_LAYOUT_VALID_PLOT_DESIGNS = ['default', 'rcb', 'crd', 'manual'];

export class Trial {
  private slugGenerator: SlugGenerator | null = null;

  randomizationSeed: number;

  private validateRcbSquare = {
    onlyIf: () => this.plotDesign() === 'rcb' && this.rcbType() === 'rcb_rect',
  };

  autoNameTrials: boolean;
  editMode: EditMode = 'library';

  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({ serverError: true });
  nameSuffix = ko.observable('').extend({
    pattern: {
      params: '^[a-zA-Z0-9_]+$',
      message: i18n.t('Can only contain letters, numbers and underscores')(),
    },
  });
  nameSlug = ko.observable('').extend(slugValidation);
  state = ko.observable(TrialState.Draft).extend({
    required: true,
  });
  plotNaming = ko.observable<PlotNaming>('default').extend({
    required: true,
    serverError: true,
  });
  rangeDirection = ko.observable<RangeDirection>('top_down').extend({
    required: true,
  });
  plotDesign = ko.observable('default').extend({
    required: true,
    serverError: true,
  });
  rcbType = ko.observable<datasetsApi.RCBType>(null);
  rowLength = ko.observable('').extend({
    digit: true,
    min: 1,
  });
  rcbBlockWidth = ko.observable('').extend({
    digit: true,
    min: 1,
    required: this.validateRcbSquare,
  });
  rcbBlocksPerRow = ko.observable('').extend({
    digit: true,
    min: 1,
    required: this.validateRcbSquare,
  });
  separateTestSubjects = ko.observable(false);
  startingCorner = ko.observable<StartingCorner>('top_left');
  walkOrder = ko.observable<WalkOrder>('top_down');
  trialType = ko.observable<TrialTypeData>(null);
  descriptionJson = ko.observable<I18nText>().extend({
    serverError: true,
  });
  plants = ko.observable<number>(null).extend({
    digit: true,
    min: 0,
  });
  spaceBetweenRows = ko.observable<number>(null).extend({
    number: true,
    min: 0,
  });
  spaceInsideRows = ko.observable<number>(null).extend({
    number: true,
    min: 0,
  });
  squareMetersPerPlot = ko.observable<number>(null).extend({
    number: true,
    min: 0,
  });
  rowsPerPlot = ko.observable<number>(null).extend({
    digit: true,
    min: 0,
  });
  border = ko.observable('');
  fieldSelection = ko.observable('');
  plotLength = ko.observable<number>(null).extend({
    number: true,
    min: 0,
  });
  plotWidth = ko.observable<number>(null).extend({
    number: true,
    min: 0,
  });
  scheduledPlantingDate = ko.observable<Date>(null);
  crop = ko.observable<CropData>(null);
  createdAt = ko.observable<Date | null>(null);
  cultivationType = ko.observable<DimensionData>(null);
  fieldPreparationType = ko.observable<DimensionData>(null);
  irrigationType = ko.observable<DimensionData>(null);
  country = ko.observable<CountryData>(null);
  region = ko.observable<RegionData>(null);
  agroRegion = ko.observable<AgroRegionData>(null);
  season = ko.observable<SeasonData>(null);
  customers = ko.observableArray<NameData>(null);
  protocol = ko.observable('');
  isCommercial = ko.observable(false);
  lastMeasurement = ko.observable<Date>(null);
  lastStaff = '';
  collectedMeasurements = ko.observable(0);
  measurementsToCollect = ko.observable(0);
  measurementsCalulationsAvailable = ko.observable(true);
  dataCollectionStatus = ko.observable<'done' | 'in_progress' | 'late' | 'calculating'>('done');
  measurementsToCollectByToday = ko.observable(0);
  measurementsOptional = ko.observable(0);
  modified = ko.observable<Date>(null);
  assigned = ko.observable(false);
  isLegacyTrial = false;
  enableBidirSync = ko.observable(true);
  disablePicturesFromGallery = ko.observable(false);
  wasCreatedUsingTreatmentsFeature = ko.observable(false);
  requiredLocationAccuracy = ko.observable(getLocationAccuracies()[0].value);
  warnLocationAccuracy = ko.observable(false);
  enforceReasonForEditingObservations = ko.observable(false);
  owners = ko.observableArray<UserData>().extend({
    required: true,
  });
  tpp = ko.observable<TPPListData>(null);
  scheduledVisits = ko.observableArray<ScheduledVisitData>(null);
  traits = ko.observableArray<MeasurementMetaData>(null);
  sites = ko.observableArray<DimensionData>(null);
  project = ko.observable<NameData>(null);
  partner = ko.observable<NameData>(null);
  gateInnovationId = ko.observable<number>(null);
  gateInnovationName = ko.observable<string>(null);
  template = false;
  canEditMMLibrary = false;
  createdFromTemplateId: string = null;

  private subscriptions: KnockoutSubscription[] = [];

  editUrl = ko.pureComputed(() => {
    if (this.isLegacyTrial) {
      return '';
    } else {
      return '/trials/' + this.id() + '/';
    }
  });
  formattedScheduledPlantingDate = ko.pureComputed(() => {
    return tryFormatDateShort(this.scheduledPlantingDate());
  });
  datasetsUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/datasets/';
  });

  exportTrialUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/export/';
  });

  assignStaffUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/assign_staff/';
  });

  assignGroupsUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/assign/';
  });

  copyUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/copy/';
  });

  dashboardUrl = ko.pureComputed(() => {
    return '/dashboard/' + this.id() + '/';
  });

  dashboardMapUrl = ko.pureComputed(() => {
    return '/dashboard/' + this.id() + '/map/';
  });

  importFactsUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/import_observations/';
  });

  exportPlotsUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/export_plots/';
  });

  printPlotsUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/print_plots/';
  });

  fieldBookUrl = ko.pureComputed(() => {
    return '/trials/' + this.id() + '/field_book/';
  });

  gateInnovationLink = ko.pureComputed(() => {
    //TODO: Use environment variables
    //@ts-ignore
    const gateBaseUrl = {
      local: 'http://localhost:8081/',
      develop: '',
      staging: 'https://qa-sfsa-agserv-n553ishx4a-ew.a.run.app/',
      production: 'https://gate.sfsa-tools.org/',
    }[process.env.APP_ENV];
    return `${gateBaseUrl}innovations/${this.gateInnovationId()}/`;
  });

  status = ko.pureComputed(() => getTrialStateLabel(this.state()));

  ownerNames = ko.pureComputed(() => {
    return this.owners()
      .map((owner) => owner.name)
      .join(', ');
  });

  allowDisableSites = ko.pureComputed(() => {
    return this.trialType()?.allow_disable_sites ?? true;
  });

  static makeRandomizationSeed(): number {
    return Math.floor(Math.random() * 2147483647);
  }

  constructor(userData: UserData, data?: TrialData, template?: boolean) {
    if (APP_CONFIG.TRIAL_FIELDS_SPECIAL && data && data.created_from_template_id) {
      this.country = this.country.extend({ required: true });
      this.season = this.season.extend({ required: true });
    }

    if (userData) {
      this.owners([userData]);
    }

    if (data) {
      this.randomizationSeed = data.randomization_seed;
      this.editMode = data.edit_mode;

      this.id(data.id);
      this.nameJson(data.name_json);
      this.state(data.state as TrialState);
      this.nameSlug(data.name_slug);
      this.plotNaming(data.plot_naming);
      this.rangeDirection(data.range_direction);
      this.plotDesign(data.plot_design);
      this.rcbType(data.rcb_type);
      this.createdAt(new Date(data.created));
      this.rowLength(data.row_length?.toString());
      this.rcbBlockWidth(data.rcb_block_width?.toString());
      this.rcbBlocksPerRow(data.rcb_blocks_per_row?.toString());
      this.separateTestSubjects(data.separate_test_subjects);
      this.startingCorner(data.starting_corner);
      this.walkOrder(data.walk_order);
      this.trialType(data.trial_type);
      this.descriptionJson(data.description_json);
      this.plants(data.plants);
      this.spaceBetweenRows(data.space_between_rows);
      this.spaceInsideRows(data.space_inside_rows);
      this.squareMetersPerPlot(data.square_meters_per_plot);
      this.rowsPerPlot(data.rows_per_plot);
      this.border(data.border);
      this.fieldSelection(data.field_selection);
      this.plotLength(data.plot_length);
      this.plotWidth(data.plot_width);
      this.scheduledPlantingDate(parseDate(data.scheduled_planting_date));
      this.crop(data.crop);
      this.cultivationType(data.cultivation_type);
      this.fieldPreparationType(data.field_preparation_type);
      this.irrigationType(data.irrigation_type);
      this.country(data.country);
      this.region(data.region);
      this.agroRegion(data.agro_region);
      this.season(data.season);
      this.customers(data.customers);
      this.protocol(data.protocol);
      this.isCommercial(data.is_commercial);
      this.lastMeasurement(parseDateTime(data.last_timestamp));
      this.lastStaff = data.last_staff;
      this.collectedMeasurements(data.measurements_count ?? 0);
      this.measurementsToCollect(data.measurements_to_collect ?? 0);
      this.measurementsCalulationsAvailable(
        data.measurements_to_collect !== null || data.measurements_count !== null
      );
      this.dataCollectionStatus(data.data_collection_status ?? 'done');
      this.measurementsToCollectByToday(data.measurements_to_collect_by_today ?? 0);
      this.measurementsOptional(data.optional_measurements_count ?? 0);
      this.modified(parseDateTime(data.modified));
      this.assigned(data.assigned);
      this.isLegacyTrial = data.is_legacy_trial;
      this.enableBidirSync(data.enable_bidir_sync);
      this.disablePicturesFromGallery(data.disable_pictures_from_gallery);
      this.wasCreatedUsingTreatmentsFeature(data.was_created_using_treatments_feature);
      this.requiredLocationAccuracy(
        data.required_location_accuracy?.toString() ?? getLocationAccuracies()[0].value
      );
      this.warnLocationAccuracy(data.warn_location_accuracy);
      if (data.owners && data.owners.length > 0) {
        // old trials and data generated from a template may have not it set
        this.owners(data.owners);
      }
      this.tpp(data.tpp);
      this.scheduledVisits(data.scheduled_visits);
      this.traits(data.traits);
      this.sites(data.sites);
      if (data.tpp) {
        this.project(data.tpp.project);
      } else {
        this.project(data.project);
      }
      this.partner(data.partner);
      this.gateInnovationId(data.gate_innovation_id);
      this.gateInnovationName(data.gate_innovation_name);
      this.template = data.template;
      this.createdFromTemplateId = data.created_from_template_id;
      this.enforceReasonForEditingObservations(data.enforce_reason_for_editing_observations);

      for (let obs of [this.rowLength, this.rcbBlockWidth, this.rcbBlocksPerRow]) {
        obs.isModified(false);
      }
    } else {
      // for new trials
      this.randomizationSeed = Trial.makeRandomizationSeed();
      this.template = template;
      this.wasCreatedUsingTreatmentsFeature(
        this.editMode === 'library' && session.tenant().treatment_management_enabled
      );
    }

    if (!this.template) {
      this.crop = this.crop.extend({ required: true });
    }

    this.canEditMMLibrary = canEditMMLibrary();

    this.autoNameTrials = APP_CONFIG.AUTO_NAME_TRIALS && !this.template;
    if (this.autoNameTrials) {
      let name = translate(this.nameJson());
      let prefix = this.namePrefix();
      if (data && name && name.indexOf(prefix) !== 0) {
        // this is an already existing trial with a name that doesn't match the current auto-naming algorithm,
        // let the user edit the name.
        this.autoNameTrials = false;
      } else {
        if (data && name) {
          this.nameSuffix(name.slice(prefix.length));
        }

        this.subscriptions.push(this.namePrefix.subscribe(this.updateNameSlug));
        this.subscriptions.push(this.nameSuffix.subscribe(this.updateNameSlug));
      }
    }
    if (!this.autoNameTrials) {
      this.nameJson = this.nameJson.extend({ i18nTextRequired: true });
      this.slugGenerator = new SlugGenerator(this.nameJson, null, this.nameSlug, {
        canEdit: this.isDraft(),
        fillIfEmpty: true,
      });
    }

    if (APP_CONFIG.TRIAL_FIELDS_SPECIAL && data && data.created_from_template_id) {
      this.country.isModified(true);
      this.season.isModified(true);
    }
  }

  dispose() {
    this.slugGenerator?.dispose();
    this.subscriptions.forEach((sub) => sub.dispose());
    this.subscriptions = [];
  }

  toData(): TrialData {
    let nameJson = this.nameJson();
    if (this.autoNameTrials) {
      nameJson = asI18nText(this.getAutoName());
    }

    return {
      id: this.id(),
      name_json: nameJson,
      state: this.state(),
      name_slug: this.nameSlug(),
      plot_naming: this.plotNaming(),
      range_direction: this.rangeDirection(),
      plot_design: this.plotDesign(),
      rcb_type: this.rcbType(),
      row_length: emptyToNull(this.rowLength()),
      rcb_block_width: emptyToNull(this.rcbBlockWidth()),
      rcb_blocks_per_row: emptyToNull(this.rcbBlocksPerRow()),
      separate_test_subjects: this.separateTestSubjects(),
      starting_corner: this.startingCorner(),
      walk_order: this.walkOrder(),
      randomization_seed: this.randomizationSeed,
      trial_type: this.trialType(),
      description_json: this.descriptionJson(),
      plants: serializeNumber(this.plants()),
      space_between_rows: serializeNumber(this.spaceBetweenRows()),
      space_inside_rows: serializeNumber(this.spaceInsideRows()),
      square_meters_per_plot: serializeNumber(this.squareMetersPerPlot()),
      rows_per_plot: serializeNumber(this.rowsPerPlot()),
      border: this.border(),
      field_selection: this.fieldSelection(),
      plot_length: serializeNumber(this.plotLength()),
      plot_width: serializeNumber(this.plotWidth()),
      scheduled_planting_date: serializeDate(this.scheduledPlantingDate()),
      crop: this.crop(),
      cultivation_type: this.cultivationType(),
      field_preparation_type: this.fieldPreparationType(),
      irrigation_type: this.irrigationType(),
      country: this.country(),
      region: this.region(),
      agro_region: this.agroRegion(),
      season: this.season(),
      customers: this.customers().slice(), // take copy for comparisons in wizard
      protocol: this.protocol(),
      is_commercial: this.isCommercial(),
      enable_bidir_sync: this.enableBidirSync(),
      disable_pictures_from_gallery: this.disablePicturesFromGallery(),
      required_location_accuracy: parseInt(this.requiredLocationAccuracy(), 10),
      was_created_using_treatments_feature: this.wasCreatedUsingTreatmentsFeature(),
      warn_location_accuracy: this.warnLocationAccuracy(),
      owners: this.owners().slice(), // take copy for comparisons in wizard
      tpp: this.tpp(),
      project: this.project(),
      partner: this.partner(),
      template: this.template,
      created_from_template_id: this.createdFromTemplateId,
      gate_innovation_id: this.gateInnovationId(),
      gate_innovation_name: this.gateInnovationName(),
      enforce_reason_for_editing_observations: this.enforceReasonForEditingObservations(),
    };
  }

  canActivate = ko.pureComputed(() => {
    return !this.template && this.state() !== TrialState.Active;
  });

  canSetCompleted = ko.pureComputed(() => {
    return (
      !this.template &&
      (this.isDraft() || this.state() === TrialState.Active || this.state() === TrialState.Archived)
    );
  });

  canSetDataValidated = ko.pureComputed(() => {
    return (
      !this.template &&
      (this.isDraft() ||
        this.state() === TrialState.Active ||
        this.state() === TrialState.Archived ||
        this.state() === TrialState.Completed)
    );
  });

  canArchive = ko.pureComputed(() => {
    return this.isDraft() || this.state() === TrialState.Active || this.state() === TrialState.DataValidated;
  });

  isDraft = ko.pureComputed(() => {
    return (
      this.state() === TrialState.Draft ||
      this.state() === TrialState.Preview ||
      this.state() === TrialState.Approved
    );
  });

  isNotDraft = ko.pureComputed(() => {
    return !this.isDraft();
  });

  measurementCalculationIsAvailable = ko.pureComputed(() => {
    // NOTE: Please keep the two locations in sync.
    // https://github.com/3smobile/quicktrials-web/blob/develop/main/trials/tasks.py#L89
    if (
      [TrialState.Draft, TrialState.Preview, TrialState.Approved, TrialState.Archived].includes(this.state())
    ) {
      return true;
    }
    return this.measurementsCalulationsAvailable();
  });

  hasDefaultDesign() {
    return this.plotDesign() == 'default';
  }

  hasRCBDesign() {
    return this.plotDesign() == 'rcb';
  }

  hasLatinSquareDesign() {
    return this.plotDesign() == 'latin_square';
  }

  hasSplitPlotDesign() {
    return this.plotDesign() == 'split_plot';
  }

  hasCustomerDesign() {
    return this.plotDesign() == 'customer';
  }

  hasAlphaLatticeDesign() {
    return this.plotDesign() == 'alpha_lattice';
  }

  designSupportsMirroring() {
    return !this.hasCustomerDesign() && !this.hasManualDesign();
  }

  hasManualDesign() {
    return this.plotDesign() == 'manual';
  }

  customerNames(): string {
    return this.customers()
      .map((c) => c.name)
      .join(', ');
  }

  namePrefix = ko.pureComputed<string>(() => {
    let parts: string[] = [];
    if (this.country()) {
      parts.push(this.country().iso_country_code);
    }
    if (this.scheduledPlantingDate()) {
      parts.push(this.scheduledPlantingDate().getFullYear().toString());
    }
    if (this.season()) {
      parts.push(this.season().code || translate(this.season().name_json));
    }
    if (this.crop()) {
      parts.push(this.crop().anonymized_code || translate(this.crop().name_json));
    }
    if (this.trialType()) {
      parts.push(this.trialType().anonymized_code || translate(this.trialType().name_json));
    }
    for (let customer of this.customers()) {
      parts.push(customer.name);
    }

    return parts.join('_').toUpperCase();
  });

  private updateNameSlug = () => {
    if (this.isDraft()) {
      this.nameSlug(generateSlug(this.getAutoName()));
    }
  };

  private getAutoName(): string {
    return this.namePrefix() + this.nameSuffix();
  }
}

export class TrialWizardWarningController {
  warningMessages = ko.observableArray<WarningMessageType>([]);

  addMessage(type: WARNING_MESSAGES_TYPES, message: string) {
    this.removeMessage(type);
    this.warningMessages.push({ type, message });
  }

  removeMessage(type: WARNING_MESSAGES_TYPES) {
    this.warningMessages(
      this.warningMessages().filter((message: WarningMessageType) => message.type !== type)
    );
  }
}

export class TrialWizard {
  // if defined, and the trial hasn't been saved yet,
  // the server will copy charts/anova from this trial
  copyDashboardFromId: string = undefined;

  supportedResolutions = supportedResolutions;
  resolutionId = ko.observable(supportedResolutions[defaultResolutionIndex].id);
  warningController = ko.observable<TrialWizardWarningController>(new TrialWizardWarningController());

  editMode: EditMode;
  trial = ko.observable<Trial>();
  testSubjects = ko.observableArray<DatasetDimensionMeta>();
  treatments = ko.observableArray<Treatment>();
  customPlotNumbers = ko.observable<boolean>(false);
  customPlotPosition = ko.observable<boolean>(false);
  excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects = ko.observable<boolean>(false);
  enforceReasonForEditingObservations = ko.observable<boolean>(false);
  savePlotGuides = false;
  plotGuides = ko.observable<PlotGuidesData>(null);
  spMainDMId = ko.observable<string>().extend({
    required: true,
  });
  customerDimensions = ko.observableArray<DimensionData>();
  spRepBlocksPerRow = ko.observable<string>().extend({
    digit: true,
  });
  manualAllowCreateDimension = ko.observable(false);
  alBlockSize = ko.observable('').extend({
    digit: true,
    min: 2,
    required: { onlyIf: () => this.trial()?.plotDesign() === 'alpha_lattice' },
    validation: [
      {
        validator: (value: string) => {
          let size = parseInt(value, 10);
          if (isNaN(size) || size < 2) {
            return true;
          }

          return this.plotsPerReplication() % size === 0;
        },
        message: i18n.t([
          'number_of_plots_replication_error',
          'The number of plots in a replication must be divisible by the plots per block',
        ])(),
      },
      {
        validator: (value: string) => {
          let size = parseInt(value, 10);
          if (isNaN(size) || size < 2) {
            return true;
          }
          let replications = parseInt(this.replications().toString(), 10);
          if (isNaN(replications) || replications < 2 || replications > 4) {
            return true;
          }
          let nBlocks = this.plotsPerReplication() / size;

          if (replications === 3 && nBlocks % 2 === 0) {
            return size < nBlocks;
          } else {
            return size <= nBlocks;
          }
        },
        message: i18n.t([
          'number_of_plots_block_error',
          'The number of plots in a block must be less than the number of blocks in each replication',
        ])(),
      },
      {
        validator: (value: string) => {
          let size = parseInt(value, 10);
          if (isNaN(size) || size < 2) {
            return true;
          }
          let replications = parseInt(this.replications().toString(), 10);
          if (isNaN(replications) || replications !== 4) {
            return true;
          }
          let nBlocks = this.plotsPerReplication() / size;

          return nBlocks % 3 !== 0 && nBlocks % 2 !== 0;
        },
        message: i18n.t(
          "The number of blocks in a replication can't be a multiple of 2 or 3 when there are 4 replications"
        )(),
      },
    ],
  });
  alReplicationsPerRow = ko.observable('').extend({ digit: true, min: 1 });
  alBlocksPerReplicationRow = ko.observable('').extend({ digit: true, min: 1 });
  alPlotsPerBlockRow = ko.observable('').extend({ digit: true, min: 1 });
  sites = ko.observableArray<LimitToDimension>().extend({ required: true });
  canAddSites = ko.observable(false);
  replications = ko.observable<number>(1).extend({
    digit: true,
    serverError: true,
    validation: {
      validator: (value) => {
        if (!this.trial() || !this.trial().hasAlphaLatticeDesign()) {
          return true;
        }

        let replications = parseInt(value, 10);
        return !isNaN(replications) && replications >= 2 && replications <= 4;
      },
      message: i18n.t('Alpha lattices must have a number of replications between 2 and 4')(),
    },
  });
  traitActions = ko.observableArray<TraitAction>();
  datasets = ko.observableArray<DatasetWizard>();
  manualScheduledVisits = ko.observableArray<ScheduledVisit>();
  libraryScheduledVisits = ko.observableArray<DerivedScheduledVisit>();
  librarySVEdit = new Map<string, DSVEdit>();
  private librarySVLoaded = false;
  initialScheduledVisitDays: ScheduledVisitDaysData[] = [];
  traits: TrialTraits;
  forceRegeneratePlots = ko.observable<'full' | 'new_sites' | 'no'>('no');
  initialTemplate: boolean;
  template = ko.pureComputed(() => this.trial()?.template ?? this.initialTemplate);
  needToConfirmMissingTppTraits: boolean;
  tppTraitNameBySlug = new Map<string, string>();

  isDirty = ko.observable(false);

  errors = ko.validation.group(this);

  customerDimensionsSearch: FormSelectSearchConfiguration<DimensionData> = {
    getSummaryName: (entity) => entity.name_json,
    list: (params) => {
      const crop = this.trial().crop();
      const filters = { crop_ids: crop ? [crop.id] : undefined };

      return listForDMS(this.getCustomerDimensionDmIds(), filters, params);
    },
    entities: this.customerDimensions,
  };

  moveSVTraitUp = (svTrait: ScheduledVisitTraitData) => {
    if (this.editMode === 'library') {
      this.libraryMoveSVTrait(svTrait, 'up');
    } else {
      this.manualMoveSVTrait(svTrait, 'up');
    }
  };

  moveSVTraitDown = (svTrait: ScheduledVisitTraitData) => {
    if (this.editMode === 'library') {
      this.libraryMoveSVTrait(svTrait, 'down');
    } else {
      this.manualMoveSVTrait(svTrait, 'down');
    }
  };

  private libraryMoveSVTrait(svTrait: ScheduledVisitTraitData, direction: 'up' | 'down') {
    const svs = this.scheduledVisits();
    let idxInGroup = -1;
    let group: ScheduledVisitTraitData[] | undefined = undefined;
    for (let svIdx = 0; svIdx < svs.length && idxInGroup == -1; svIdx++) {
      const sv = svs[svIdx];
      const groupedObservations = ko.unwrap(sv.groupedObservations);
      for (
        let groupedObsIdx = 0;
        groupedObsIdx < groupedObservations.length && idxInGroup == -1;
        groupedObsIdx++
      ) {
        group = groupedObservations[groupedObsIdx].observations;
        idxInGroup = group.findIndex((obs) => obs.mm_id == svTrait.mm_id);
      }
    }

    if (idxInGroup == -1) return;
    if (direction === 'up' && idxInGroup === 0) return;
    if (direction === 'down' && idxInGroup === group.length - 1) return;

    const swapWithIdx = idxInGroup + (direction === 'up' ? -1 : +1);
    const swapWithMMId = group[swapWithIdx].mm_id;
    const idx1 = this.traits.selectedTraits().findIndex((trait) => trait.mmId === svTrait.mm_id);
    const idx2 = this.traits.selectedTraits().findIndex((trait) => trait.mmId === swapWithMMId);

    swap(this.traits.selectedTraits, idx1, idx2);
    swap(group, idxInGroup, swapWithIdx);
    this.scheduledVisits.valueHasMutated();
  }

  private manualMoveSVTrait(svTrait: ScheduledVisitTraitData, direction: 'up' | 'down') {
    const dataset = this.datasets().find((ds) => ds.id() === svTrait.ds_id);
    const mm = dataset.measurementMetas().find((mm) => mm.id() === svTrait.mm_id);
    if (direction === 'up') {
      dataset.orderedMeasurementMetas.moveUp(mm);
    } else {
      dataset.orderedMeasurementMetas.moveDown(mm);
    }
    for (const sv of this.manualScheduledVisits()) {
      sv.onObservationsChange();
    }
  }

  manualSVConfig: FormNestedEntitiesConfiguration<ScheduledVisit> = {
    title: '',
    addTitle: i18n.t('Schedule new visit')(),
    missingTitle: i18n.t('Not defined')(),
    entities: this.manualScheduledVisits,

    canDisable: () => false,
    isTrialActive: () => false, // Since canDisable is false this will never be visible
    disabled: () => false,
    disable: () => {},

    canRemove: (sv) => !sv.id || this.trial().isDraft(),
    add: () => {
      let sv = new ScheduledVisit(this.datasets);
      this.scheduledVisits.push(sv);
      return sv;
    },
    remove: (sv) => {
      sv.dispose();
      this.scheduledVisits.remove(sv);
    },
    hasErrors: (sv) => sv.hasErrors(),
    showErrors: (sv) => sv.showErrors(),
    actions: [],
    getSummaryName: (sv) => sv.nameJson(),
    selectedEntity: ko.observable(null),
  };

  librarySVConfig: FormNestedEntitiesConfiguration<DerivedScheduledVisit> = {
    title: '',
    addTitle: '',
    missingTitle: '',
    entities: this.libraryScheduledVisits,

    canDisable: () =>
      [TrialState.Draft, TrialState.Preview, TrialState.Active].includes(this.trial().state()) &&
      canEditTrial(this.userData, this.trial()),

    isTrialActive: () => this.trial().state() === TrialState.Active,
    disabled: (sv) => sv.disabled(),
    disable: (sv) => sv.disable(),

    canRemove: (sv) => false,
    add: () => null,
    remove: (sv) => {},
    hasErrors: (sv) => sv.hasErrors(),
    showErrors: (sv) => sv.showErrors(),
    actions: [],
    getSummaryName: (sv) => sv.nameJson,
    selectedEntity: ko.observable(null),
  };

  scheduledVisits: ko.ObservableArray<ScheduledVisitForTimeline>;
  scheduledVisitsConfig: FormNestedEntitiesConfiguration<ScheduledVisitForTimeline>;

  plotsModel: PlotsEditModel;

  private subscriptions: KnockoutSubscription[] = [];
  private tppControlSubscription: KnockoutSubscription | undefined;
  private dominantCvsControlSubscriptions = new Map<string, KnockoutSubscription>();

  constructor(
    public siteDM: DimensionMeta,
    public replicationDM: DimensionMeta,
    public userData: UserData,
    template: boolean,
    defaultEditMode: EditMode,
    data?: TrialWizardData
  ) {
    const editMode = data?.edit_mode ?? defaultEditMode;

    this.initialTemplate = template;
    if (editMode === 'library') {
      this.scheduledVisits = this.libraryScheduledVisits;
      this.scheduledVisitsConfig = this.librarySVConfig;
    } else {
      this.scheduledVisits = this.manualScheduledVisits;
      this.scheduledVisitsConfig = this.manualSVConfig;
    }

    if (data) {
      this.resolutionId(getResolutionId(data));

      this.copyDashboardFromId = data.copy_dashboard_from_id;
      this.editMode = data.edit_mode;
      this.trial(new Trial(userData, data.trial));
      this.customPlotNumbers(data.custom_plot_numbers);
      this.customPlotPosition(data.custom_plot_position);
      this.plotGuides(data.plot_guides);
      if (!data.trial.id) {
        this.savePlotGuides = true;
      }
      this.spMainDMId(data.sp_main_dm_id);
      this.alBlockSize(data.al_block_size?.toString());
      this.alReplicationsPerRow(data.al_replications_per_row?.toString());
      this.alBlocksPerReplicationRow(data.al_blocks_per_replication_row?.toString());
      this.alPlotsPerBlockRow(data.al_plots_per_block_row?.toString());
      this.customerDimensions(data.customer_dimensions);
      this.sites(data.sites.map((d) => new LimitToDimension(null, siteDM, d)));
      this.canAddSites(data.can_add_sites);
      this.spRepBlocksPerRow(data.sp_rep_blocks_per_row ? data.sp_rep_blocks_per_row.toString() : '');
      this.manualAllowCreateDimension(data.manual_allow_create_dimension);
      this.excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects(
        data.exclude_from_generation_combinations_of_control_and_non_control_test_subjects
      );
      this.replications(data.replications);
      this.traitActions(data.trait_actions.map((traitActionData) => new TraitAction(traitActionData)));

      let seenSlugs: { [key: string]: boolean } = {};

      this.testSubjects(
        data.test_subjects.map((d) => {
          let slug = d.dimension_meta_id.slug;
          let isPredefined = false;

          if (DatasetDimensionMeta.predefinedSlugs.indexOf(slug) >= 0 && !seenSlugs[slug]) {
            seenSlugs[slug] = true;
            isPredefined = true;
          }

          return new DatasetDimensionMeta(this, d, isPredefined);
        })
      );

      this.treatments(data.treatments.map((treatmentData) => new Treatment(treatmentData)));

      // must be initialized last, because it depends on the trial wizard data
      this.datasets(data.datasets.map((d) => new DatasetWizard(this, d)));
      // this one depends on datasets
      this.scheduledVisits(data.scheduled_visits.map((data) => new ScheduledVisit(this.datasets, data)));

      this.traits = new TrialTraits(data.traits);
      this.initialScheduledVisitDays = data.scheduled_visits_days;

      for (let obs of [
        this.alBlockSize,
        this.alReplicationsPerRow,
        this.alBlocksPerReplicationRow,
        this.alPlotsPerBlockRow,
      ]) {
        obs.isModified(false);
      }
    } else {
      this.editMode = editMode;
      this.trial(new Trial(userData, undefined, template));
      this.traits = new TrialTraits([]);
    }
    this.trial().editMode = this.editMode;
    // preselect split plot main subject
    this.subscriptions.push(this.testSubjects.subscribe(this.updateSPMainDim));
    this.subscriptions.push(
      this.trial().tpp.subscribe(async () => {
        this.checkTrialTPP(this.testSubjects(), this.trial().tpp());

        if (this.trial().tpp() != null && this.testSubjects().length === 0 && this.trial().isDraft()) {
          await this.addCropTestSubject();
        }
        const cropVarietyTestSubject = this.getCropVarietyTestSubject();

        this.addTppDominantVarieties(cropVarietyTestSubject?.limitTo);
      })
    );

    this.subscriptions.push(
      this.testSubjects.subscribe(() => {
        this.checkTrialTPP(this.testSubjects(), this.trial().tpp());
      })
    );
    this.subscriptions.push(
      this.scheduledVisits.subscribe(() => {
        if (this.scheduledVisits().length > 0) {
          const periodicVisits = this.scheduledVisits().filter(
            (visit: DerivedScheduledVisit) => !!visit.repetitionNumberOfDays
          );
          if (periodicVisits.length > 0 && this.template()) {
            this.warningController().addMessage(
              WARNING_MESSAGES_TYPES.PERIODIC_VISIT,
              i18n.t(
                'Periodic visits do not appear in trial templates. They will be generated in trials from this template'
              )()
            );
          } else {
            this.warningController().removeMessage(WARNING_MESSAGES_TYPES.PERIODIC_VISIT);
          }
        }
      })
    );

    this.subscriptions.push(
      this.traits.selectedTraits.subscribe(async () => {
        if (this.traits.selectedTraits().length > 0) {
          const dependenciesPromises = chunk(this.traits.selectedTraits(), 100).map((toAddChunk) =>
            listDependencies({
              for_ids: toAddChunk.map((mm) => mm.mmId),
              crop_id: this.trial().crop()?.id,
            })
          );
          const dependenciesChunks = await Promise.all(dependenciesPromises);

          let invalid: datasetsApi.MeasurementMetaData[] = [];
          for (const dependenciesChunk of dependenciesChunks) {
            invalid.concat(...dependenciesChunk.invalid);
          }

          this.showTraitsWarningMessage(invalid);
        }
        this.traits.loaded() && this.checkTPPTraits();
      })
    );

    this.subscriptions.push(
      this.trial().trialType.subscribe(() => {
        this.checkTPPTraits();
      })
    );

    this.subscriptions.push(
      this.testSubjects.subscribe(() => {
        const cropVarietyTestSubject = this.getCropVarietyTestSubject();
        if (cropVarietyTestSubject != undefined) {
          if (this.tppControlSubscription == undefined) {
            this.tppControlSubscription = cropVarietyTestSubject.limitTo.subscribe(() =>
              this.checkTppControlVarieties()
            );
            this.subscriptions.push(this.tppControlSubscription);
          }
        } else {
          this.tppControlSubscription?.dispose();
        }
      })
    );

    const cropVarietyTestSubject = this.getCropVarietyTestSubject();
    if (cropVarietyTestSubject) {
      this.tppControlSubscription = cropVarietyTestSubject.limitTo.subscribe(() =>
        this.checkTppControlVarieties()
      );
      this.subscriptions.push(this.tppControlSubscription);
      this.checkTppControlVarieties();
    }

    this.updateSPMainDim(this.testSubjects());

    this.subscriptions.push(this.scheduledVisitsConfig.selectedEntity.subscribe(this.reorderVisits));
    this.reorderVisits();

    this.setPlots(data?.plots ?? datasetsApi.EMPTY_PLOT_LIST_DATA, !data?.trial.id);
  }

  private showTraitsWarningMessage = (traits: datasetsApi.MeasurementMetaData[]) => {
    this.warningController().removeMessage(WARNING_MESSAGES_TYPES.TRAITS_WRONG_CROP);
    if (traits.length > 0) {
      const notAddedNames = `: ${traits.map((mm) => translate(mm.name_json)).join(',')}`;
      const cropName = ` "${translate(this.trial().crop().name_json)}"`;
      const message = i18n.t("The following traits weren't added")();
      const reason = i18n.t('They are not assigned to crop')();
      const fullMessage = message + notAddedNames + '. ' + reason + cropName;

      this.warningController().addMessage(WARNING_MESSAGES_TYPES.TRAITS_WRONG_CROP, fullMessage);
    }
  };

  checkTPPTraits = async () => {
    this.warningController().removeMessage(WARNING_MESSAGES_TYPES.TPP_TRAITS_NOT_IN_TRIAL);
    if (this.trial().tpp()) {
      const missingTppTraitNames: string[] = this.getMissingTraitsNamesFromTpp();
      if (missingTppTraitNames.length > 0) {
        const message = `${i18n.t(
          'You do not have all required traits of the attached TPP in your trial:'
        )()} ${missingTppTraitNames.join(', ')}`;
        this.warningController().addMessage(WARNING_MESSAGES_TYPES.TPP_TRAITS_NOT_IN_TRIAL, message);
      }
    }
  };

  private addDominantCvControlCheck(dominantCvLimitTo: LimitToDimension) {
    const id = dominantCvLimitTo.id();
    if (!this.dominantCvsControlSubscriptions.has(id)) {
      this.dominantCvsControlSubscriptions.set(
        id,
        dominantCvLimitTo.control.subscribe(() => this.checkTppControlVarieties())
      );
    }
  }

  private getCropVarietyTestSubject() {
    return this.testSubjects().find((ts) => ts.useForPlot() && ts.dimensionMeta()?.slug() == 'crop_variety');
  }

  getMissingTraitsNamesFromTpp = (): string[] => {
    let selectedTraitSlugs = new Set<string>();
    if (this.trial().editMode === 'manual') {
      this.datasets().forEach((dataset) => {
        dataset.measurementMetas().forEach((measurementMeta) => {
          selectedTraitSlugs.add(measurementMeta.nameSlug());
        });
      });
    } else {
      selectedTraitSlugs = new Set(this.traits.selectedTraits().map((trait) => trait.nameSlug));
    }
    return this.trial()
      .tpp()
      .traits.filter((trait) => {
        if (!trait.trial_types?.length || !this.trial().trialType()) {
          return true;
        }
        return trait.trial_types
          .map((traitTrialType) => traitTrialType.id)
          .includes(this.trial().trialType()?.id);
      })
      .map((trait) => trait.measurement_meta)
      .filter((trait) => trait && !selectedTraitSlugs.has(trait.name_slug))
      .map((trait) => translate(trait.name_json));
  };

  private async addCropTestSubject() {
    let cvTestSubject = new DatasetDimensionMeta(this);
    cvTestSubject.useForPlot(true);
    cvTestSubject.dimensionMeta(new DimensionMeta(await dimensionMetasApi.retrieve('crop_variety')));
    cvTestSubject.isPredefined(true);
    cvTestSubject.order(0);
    this.testSubjects.push(cvTestSubject);
  }

  private addTppDominantVarieties(limitTo: ko.ObservableArray<LimitToDimension>) {
    if (limitTo == undefined || !this.trial().isDraft() || this.trial().tpp() == null) return;
    for (const dominantCv of this.trial().tpp().dominant_varieties) {
      let dominantCvLimitTo = limitTo().find((lt) => lt.id() === dominantCv.id);
      if (dominantCvLimitTo == undefined) {
        dominantCvLimitTo = new LimitToDimension(
          this,
          this.getCropVarietyTestSubject().dimensionMeta(),
          dominantCv
        );
        dominantCvLimitTo.control(true);
        limitTo.push(dominantCvLimitTo);
      }
      this.addDominantCvControlCheck(dominantCvLimitTo);
    }
    const benchmarkVarieties = this.trial()
      .tpp()
      .traits.map((trait) => trait.benchmark_varieties.map((variety) => variety))
      .reduce((a, b) => a.concat(b), []);

    for (const benchmarkVariety of benchmarkVarieties) {
      let benchmarkVarietyLimitTo = limitTo().find((lt) => lt.id() === benchmarkVariety.id);
      if (benchmarkVarietyLimitTo == undefined) {
        benchmarkVarietyLimitTo = new LimitToDimension(
          this,
          this.getCropVarietyTestSubject().dimensionMeta(),
          benchmarkVariety
        );
        limitTo.push(benchmarkVarietyLimitTo);
      }
    }
  }

  private checkTppControlVarieties() {
    if (this.trial().tpp() == null) return;
    const limitTo = this.getCropVarietyTestSubject()?.limitTo();
    if (limitTo == undefined) return;
    const tppDominantVarieties = this.trial().tpp().dominant_varieties;

    let missingDominantCvs = [];
    let dominantCvsWithoutControl = [];
    for (const dominantCv of tppDominantVarieties) {
      const dominantCvLimitTo = limitTo.find((lt) => lt.id() === dominantCv.id);
      if (dominantCvLimitTo == undefined) {
        missingDominantCvs.push(translate(dominantCv.name_json));
      } else if (!dominantCvLimitTo.control()) {
        dominantCvsWithoutControl.push(translate(dominantCv.name_json));
      }
      dominantCvLimitTo && this.addDominantCvControlCheck(dominantCvLimitTo);
    }

    for (let [key, subscription] of Array.from(this.dominantCvsControlSubscriptions.entries())) {
      if (limitTo.findIndex((lt) => lt.id() === key) == -1) {
        subscription.dispose();
        this.dominantCvsControlSubscriptions.delete(key);
      }
    }

    this.warningController().removeMessage(WARNING_MESSAGES_TYPES.DOMINANT_VARIETY_NO_CONTROL_SET);
    if (missingDominantCvs.length > 0) {
      this.warningController().addMessage(
        WARNING_MESSAGES_TYPES.DOMINANT_VARIETY_NOT_SELECTED,
        `${i18n.t([
          'dominant_variety_not_selected',
          'A current market dominant variety is not selected: ',
        ])()} ${missingDominantCvs.join(', ')}`
      );
    }
    this.warningController().removeMessage(WARNING_MESSAGES_TYPES.DOMINANT_VARIETY_NOT_SELECTED);
    if (dominantCvsWithoutControl.length > 0) {
      this.warningController().addMessage(
        WARNING_MESSAGES_TYPES.DOMINANT_VARIETY_NO_CONTROL_SET,
        `${i18n.t([
          'dominant_variety_no_control',
          'A current market dominant variety is selected, but "control" flag is not set: ',
        ])()} ${dominantCvsWithoutControl.join(', ')}`
      );
    }
  }

  private checkTrialTPP(testSubjects: DatasetDimensionMeta[], tpp: TPPListData) {
    const slugs = testSubjects.map((ts) => {
      if (ts.useForPlot()) return ts.dimensionMeta()?.slug();
    });
    this.warningController().removeMessage(WARNING_MESSAGES_TYPES.TPP_WRONG_TEST_SUBJECT);
    if (slugs.indexOf('crop_variety') < 0 && tpp) {
      this.warningController().addMessage(
        WARNING_MESSAGES_TYPES.TPP_WRONG_TEST_SUBJECT,
        SERVER_INFO.USE_FACTORS_NAMING || session.tenant().treatment_templates_enabled
          ? i18n.t("The treatment factor has to be 'Crop Variety' to be added to a TPP")()
          : i18n.t("The test subject has to be 'Crop Variety' to be added to a TPP")()
      );
    }
  }

  private reorderVisits = () => {
    this.scheduledVisits(this.sortedVisits().map((item) => item.visit));
  };

  setPlots(plots: datasetsApi.PlotListData, isNew: boolean) {
    this.plotsModel = new PlotsEditModel(plots, this, isNew);
  }

  async loadLibrarySV(): Promise<void> {
    if (this.editMode !== 'library') {
      return;
    }

    await this.traits.load();
    const previews = await listTrialScheduledVisitsPreviews(this.trial().id(), this.traits.toData());
    this.libraryScheduledVisits(
      previews.map((p) => {
        let edit = this.librarySVEdit.get(p.id);
        if (!edit) {
          const existingVisit = this.initialScheduledVisitDays.filter((data) => data.sv_id === p.id)[0];
          const templateVisit: ScheduledVisitDaysData = {
            sv_id: p.id,
            days_offset: p.days_offset,
            days_after_planting: p.days_after_planting,
            // even if trial is new but imported from template
            disabled: existingVisit?.disabled || false,
          };
          edit = new DSVEdit(p.is_new && existingVisit ? existingVisit : templateVisit);
          this.librarySVEdit.set(p.id, edit);
        }
        edit.setPreview(p);

        return new DerivedScheduledVisit(p, edit);
      })
    );
    this.reorderVisits();
    this.librarySVLoaded = true;
    this.traits.needsLoadLibrarySV = false;
  }

  uploading = ko.pureComputed(() => {
    for (let ds of this.datasets()) {
      for (let mm of ds.measurementMetas()) {
        for (let pic of mm.pictures()) {
          if (pic.imageUpload.uploading()) {
            return true;
          }
        }
      }
    }

    return false;
  });

  getValidationTarget(): {} {
    let target = ko.utils.extend({}, this);

    if (!this.trial().hasSplitPlotDesign()) {
      delete (<any>target)['spMainDMId'];
    }
    delete target['librarySVEdit'];
    delete target['plotsModel'];
    delete target['scheduledVisitsConfig'];
    delete target['librarySVConfig'];
    delete target['subscriptions'];

    (<any>target).datasets = this.datasets().map((dataset) => dataset.getValidationTarget());

    return target;
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
    this.dominantCvsControlSubscriptions.forEach((sub) => sub.dispose());
    for (let dataset of this.datasets()) {
      dataset.dipose();
    }
    if (this.trial()) {
      this.trial().dispose();
    }
    this.manualScheduledVisits().forEach((sv) => sv.dispose());
  }

  private updateSPMainDim = (ddms: DatasetDimensionMeta[]) => {
    if (
      ddms.length > 0 &&
      (!this.spMainDMId() ||
        indexOf(ddms, (ddm) => ddm.dimensionMeta() && ddm.dimensionMeta().id() === this.spMainDMId()) === -1)
    ) {
      if (ddms.length > 0 && ddms[0].dimensionMeta()) {
        this.spMainDMId(ddms[0].dimensionMeta().id());
      }
    }
  };

  canSafelyRegeneratePlots() {
    return (
      this.forceRegeneratePlots() !== 'no' ||
      (!this.customPlotNumbers() && !this.customPlotPosition() && this.trial().isDraft())
    );
  }

  testSubjectsWithDimensionMeta(): DatasetDimensionMeta[] {
    return this.testSubjects().filter((s) => s.dimensionMeta() && s.dimensionMeta().id());
  }

  masterDataset(): DatasetWizard {
    for (let dataset of this.datasets()) {
      if (!dataset.hasExcludedPlotDM()) {
        return dataset;
      }
    }

    return null;
  }

  removeDataset(dataset: DatasetWizard) {
    this.datasets.remove(dataset);
    for (let sv of this.manualScheduledVisits()) {
      sv.onDatasetRemoved(dataset);
    }
  }

  removeMeasurementMeta(dataset: DatasetWizard, mm: MeasurementMeta) {
    dataset.orderedMeasurementMetas.remove(mm);
    for (let sv of this.manualScheduledVisits()) {
      sv.onMeasurementMetaRemoved(mm);
    }
  }

  toData(): TrialWizardData {
    let masterDataset = this.masterDataset();
    const isLibrary = this.editMode === 'library';

    let scheduledVisitDays: ScheduledVisitDaysData[] = this.initialScheduledVisitDays.map((sv) => ({
      sv_id: sv.sv_id,
      days_offset: sv.days_offset,
      days_after_planting: sv.days_after_planting,
      disabled: sv.disabled,
    }));
    if (isLibrary && this.librarySVLoaded) {
      scheduledVisitDays = this.libraryScheduledVisits().map((l) => l.toData());
    }
    // sort for change detection
    scheduledVisitDays.sort((a, b) => a.sv_id.localeCompare(b.sv_id));

    return {
      edit_mode: this.editMode,
      trial: this.trial().toData(),
      test_subjects: this.testSubjects().map((x) => x.toData(true)),
      // Do not send treatments to the server until the functionality of saving is implemented
      treatments: this.treatments().map((treatment) => treatment.toData()),
      custom_plot_numbers: this.customPlotNumbers(),
      custom_plot_position: this.customPlotPosition(),
      sp_main_dm_id: this.spMainDMId(),
      al_block_size: emptyToNull(this.alBlockSize()),
      al_replications_per_row: emptyToNull(this.alReplicationsPerRow()),
      al_blocks_per_replication_row: emptyToNull(this.alBlocksPerReplicationRow()),
      al_plots_per_block_row: emptyToNull(this.alPlotsPerBlockRow()),
      customer_dimensions: this.customerDimensions().slice(), // copy for changed detection
      sp_rep_blocks_per_row: serializeNumber(this.spRepBlocksPerRow()),
      manual_allow_create_dimension: this.manualAllowCreateDimension(),
      sites: this.sites().map((x) => this.siteToData(x)),
      can_add_sites: this.canAddSites(),
      replications: this.replications(),
      save_plot_guides: this.savePlotGuides,
      plot_guides: this.savePlotGuides ? this.plotGuides() : null,
      save_plots: this.plotsModel.needsSave,
      plots: this.plotsModel.needsSave ? this.plotsModel.toData() : this.plotsModel.emptyData(),
      datasets: this.datasets().map((x) => x.toData(x === masterDataset)),
      scheduled_visits: isLibrary
        ? []
        : this.scheduledVisits().map((sv, idx) => (sv as ScheduledVisit).toData(idx)),
      traits: isLibrary ? this.traits.toData() : [],
      scheduled_visits_days: isLibrary ? scheduledVisitDays : [],
      copy_dashboard_from_id: this.copyDashboardFromId,
      was_created_using_treatments_feature: this.trial().wasCreatedUsingTreatmentsFeature(),
      exclude_from_generation_combinations_of_control_and_non_control_test_subjects:
        this.excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects(),
      ...toResolutionData(this.resolutionId()),
      trait_actions: this.traitActions().map((traitAction) => traitAction.toData()),
      is_dirty: this.isDirty(),
    };
  }

  private siteToData(site: LimitToDimension) {
    let data = site.toData();
    if (!(this.trial()?.allowDisableSites() ?? false)) {
      data.disabled = false;
    }

    return data;
  }

  initialPlotSites() {
    return this.sites().map((site) => {
      return {
        site_id: site.id(),
        n_columns: null,
        n_ranges: null,
      };
    });
  }

  previewPlotsRequest(sites: string[]): Promise<datasetsApi.PlotGenerationData> {
    return this._generatePlotsRequest(sites, null, false, false, this.plotGuides());
  }

  generateFreshPlotsRequest(): Promise<datasetsApi.PlotGenerationData> {
    return this._generatePlotsRequest(
      this.sites().map((site) => site.id()),
      null,
      false,
      false,
      this.plotGuides()
    );
  }

  generatePlotsRequest(options: {
    fullReset: boolean;
    useCustom: boolean;
  }): Promise<datasetsApi.PlotGenerationData> {
    let preserveExisting = this.forceRegeneratePlots() === 'new_sites';
    let plotData = this.plotsModel.toData();
    if (options.fullReset) {
      plotData.plots.excluded = plotData.plots.excluded.map((_) => false);
      plotData.plots.custom_name = plotData.plots.custom_name.map((_) => false);
      plotData.plots.external_id = plotData.plots.external_id.map((_) => '');
    }
    return this._generatePlotsRequest(
      this.sites().map((site) => site.id()),
      plotData,
      preserveExisting,
      options.useCustom,
      this.plotGuides()
    );
  }

  private async _generatePlotsRequest(
    sites: string[],
    existing: datasetsApi.PlotListData,
    preserveExisting: boolean,
    useCustom: boolean,
    plotGuides: PlotGuidesData
  ): Promise<datasetsApi.PlotGenerationData> {
    let trial = this.trial();
    let ddms = this.testSubjectsWithDimensionMeta();
    let emptyResult: datasetsApi.PlotGenerationData = {
      isValid: true,
      entityId: null,
      errors: {},
      custom_plot_position: false,
      custom_plot_numbers: false,
      plots: null,
      separate_timeout: false,
    };

    let requestData: datasetsApi.PlotGenerationRequestData = {
      trial_id: trial.id(),
      preserve_existing: preserveExisting,
      plot_naming: trial.plotNaming(),
      range_direction: trial.rangeDirection(),
      plot_design: trial.plotDesign(),
      rcb_type: trial.rcbType(),
      row_length: emptyToNull(trial.rowLength()),
      rcb_block_width: emptyToNull(trial.rcbBlockWidth()),
      rcb_blocks_per_row: emptyToNull(trial.rcbBlocksPerRow()),
      al_block_size: emptyToNull(this.alBlockSize()),
      al_replications_per_row: emptyToNull(this.alReplicationsPerRow()),
      al_blocks_per_replication_row: emptyToNull(this.alBlocksPerReplicationRow()),
      al_plots_per_block_row: emptyToNull(this.alPlotsPerBlockRow()),
      separate_test_subjects: trial.separateTestSubjects(),
      starting_corner: trial.startingCorner(),
      walk_order: trial.walkOrder(),
      randomization_seed: trial.randomizationSeed,
      crop_id: trial.crop() ? trial.crop().id : null,
      custom_plot_numbers: useCustom && this.customPlotNumbers(),
      custom_plot_position: useCustom && this.customPlotPosition(),
      rcb_replications: this.replications(),
      sp_main_dm_id: null,
      sp_rep_blocks_per_row: null,
      // Disabled treatments must not be used in plot generation
      treatments: this.treatments()
        .filter((treatment) => !treatment.disabled())
        .map((treatment) => treatment.toData()),
      plot_guides: plotGuides,
      existing_plots: existing,
      dataset_dimension_metas: ddms.map((ddm) => ddm.toData(true)),
      sites,
      exclude_from_generation_combinations_of_control_and_non_control_test_subjects:
        this.excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects(),
      was_created_using_treatments_feature: trial.wasCreatedUsingTreatmentsFeature(),
    };

    if (trial.hasSplitPlotDesign()) {
      if (!this.spMainDMId()) {
        return Promise.resolve(emptyResult);
      }

      requestData.sp_main_dm_id = this.spMainDMId();
      requestData.sp_rep_blocks_per_row = serializeNumber(this.spRepBlocksPerRow());
    }

    let data = await datasetsApi.generatePlots(requestData);
    this.checkPlotGeneration(data);

    return data;
  }

  private checkPlotGeneration(data: datasetsApi.PlotGenerationData) {
    if (data.separate_timeout) {
      this.trial().separateTestSubjects(false);
      confirmDialog(
        i18n.t('Warning')(),
        i18n.t("It's impossible to separate the test subjects for this layout.")(),
        undefined,
        true
      );
    }
  }

  confirmChangeAffectingPlots(itemName: string, forceWarning = false): Promise<{}> {
    if (this.canSafelyRegeneratePlots() && !forceWarning) {
      this.forceRegeneratePlots('full');
      return Promise.resolve(null);
    }

    const title = i18n.t('Changing {{ itemName }}', { itemName })();

    const customizedPlotsMessage = i18n.t('You have customized the plots ordering. ')();
    const nonDraftMessage = i18n.t('This trial is currently active or concluded. ')();
    const messageIntro = this.trial().isNotDraft() ? nonDraftMessage : customizedPlotsMessage;
    const message = i18n.t(
      [
        'change_affecting_plots_warning',
        'Changing the {{ itemName }} will recreate the plots, and some of your customizations might be lost. Are you sure you want to continue?',
      ],
      { itemName }
    )();

    return confirmDialog(title, messageIntro + message).then(() => {
      this.forceRegeneratePlots('full');
      return null;
    });
  }

  getFirstSubjectDmId(): string {
    if (this.testSubjects().length === 0) {
      return null;
    }
    let ddm = this.testSubjects()[0];
    return ddm.dimensionMeta() ? ddm.dimensionMeta().id() : null;
  }

  private getCustomerDimensionDmIds(): string[] {
    if (this.trial().hasManualDesign()) {
      return this.testSubjects()
        .filter((ddm) => !!ddm.dimensionMeta())
        .map((ddm) => ddm.dimensionMeta().id());
    } else if (this.trial().hasCustomerDesign()) {
      return [this.getFirstSubjectDmId()];
    } else {
      return [];
    }
  }

  onTestSubjectsUpdated() {
    this.removeInvalidCustomerDimensions();
    this.updateSPMainDim(this.testSubjects());
  }

  onPlotDesignChanged() {
    this.removeInvalidCustomerDimensions();
  }

  private removeInvalidCustomerDimensions() {
    let validDmIds = this.getCustomerDimensionDmIds();
    this.customerDimensions(
      this.customerDimensions().filter((dim) => validDmIds.indexOf(dim.dimension_meta_id) >= 0)
    );
  }

  sortedVisits = ko.pureComputed<{ visit: ScheduledVisitForTimeline; idx: number }[]>(() => {
    let visits = this.scheduledVisits().map((visit, idx) => ({ visit, idx }));
    visits.sort((a, b) => {
      let daysA = parseInt(a.visit.estDaysAfterPlanting(), 10);
      let daysB = parseInt(b.visit.estDaysAfterPlanting(), 10);

      let traitDateA = parseInt(a.visit.estDaysAfterDateTrait(), 10);
      let traitDateB = parseInt(b.visit.estDaysAfterDateTrait(), 10);

      if (isNaN(daysA) && isNaN(daysB)) {
        if (traitDateA && traitDateB) {
          return traitDateA - traitDateB;
        }
        return a.idx - b.idx;
      } else if (isNaN(daysA)) {
        return 1;
      } else if (isNaN(daysB)) {
        return -1;
      } else {
        return daysA - daysB;
      }
    });

    return visits;
  });

  visitLeftPerc(item: { visit: ScheduledVisitForTimeline; idx: number }, idx: number): string {
    let days = parseInt(item.visit.estDaysAfterPlanting(), 10);
    if (isNaN(days)) {
      return '1';
    }

    let prev = this.sortedVisits()[idx - 1];
    if (prev) {
      let prevDays = parseInt(prev.visit.estDaysAfterPlanting(), 10);
      if (!isNaN(prevDays)) {
        return (days - prevDays).toString();
      }
    }

    return days.toString();
  }

  isVisitSelected(item: { visit: ScheduledVisitForTimeline; idx: number }): boolean {
    return this.scheduledVisitsConfig.selectedEntity() === item.visit;
  }

  blockWidth() {
    let design = this.trial().plotDesign();
    let rcbType = this.trial().rcbType();

    if (design === 'rcb') {
      if (rcbType === 'rcb_vertical') {
        return 1;
      } else if (rcbType === 'rcb_horizontal') {
        return this.plotsPerReplication();
      } else if (rcbType === 'rcb_rect') {
        let n = parseInt(this.trial().rcbBlockWidth(), 10);
        if (!isNaN(n) && n > 0) {
          return n;
        }
      }
    } else if (design === 'latin_square') {
      let factors = this.testSubjects();
      let last = factors[factors.length - 1];
      if (last && last.limitTo().length > 0) {
        return last.limitTo().length;
      }
    } else if (design === 'split_plot' && this.hasSplitPlotBlocksPerRow()) {
      for (let factor of this.testSubjects()) {
        if (
          factor.dimensionMeta() &&
          factor.dimensionMeta().id() === this.spMainDMId() &&
          factor.limitTo().length > 0
        ) {
          return factor.limitTo().length;
        }
      }
    } else if (design === 'default' || design === 'manual') {
      return 1;
    } else if (design === 'alpha_lattice') {
      let blockSize = parseInt(this.alBlockSize(), 10);
      if (isNaN(blockSize) || blockSize <= 0) {
        return Number.MAX_VALUE;
      }
      let perRow = parseInt(this.alPlotsPerBlockRow(), 10);
      if (!isNaN(perRow) && perRow > 0) {
        return Math.min(perRow, blockSize);
      } else {
        return blockSize;
      }
    }

    return Number.MAX_VALUE;
  }

  blockHeight() {
    let design = this.trial().plotDesign();
    let rcbType = this.trial().rcbType();

    if (design === 'rcb') {
      if (rcbType === 'rcb_horizontal') {
        let rowLength = parseInt(this.trial().rowLength(), 10);
        if (isNaN(rowLength) || rowLength < 1) {
          rowLength = this.replications() || 1;
        }
        let blockSize = this.plotsPerReplication();
        return Math.max(1, Math.floor(blockSize / rowLength));
      } else if (rcbType === 'rcb_rect') {
        let n = parseInt(this.trial().rcbBlockWidth(), 10);
        if (!isNaN(n) && n > 0) {
          return Math.ceil(this.plotsPerReplication() / n);
        }
      }
    } else if (design === 'latin_square') {
      return this.blockWidth();
    } else if (design === 'split_plot' && this.hasSplitPlotBlocksPerRow()) {
      let n = 1;
      for (let factor of this.testSubjects()) {
        if (factor.dimensionMeta() && factor.dimensionMeta().id() !== this.spMainDMId()) {
          n *= factor.limitTo().length;
        }
      }

      if (n > 0) {
        return n;
      }
    } else if (design === 'alpha_lattice') {
      let blockSize = parseInt(this.alBlockSize(), 10);
      if (isNaN(blockSize) || blockSize <= 0) {
        return Number.MAX_VALUE;
      }
      let perRow = parseInt(this.alPlotsPerBlockRow(), 10);
      if (isNaN(perRow) || perRow <= 0 || perRow > blockSize) {
        perRow = blockSize;
      }

      return Math.ceil(blockSize / perRow);
    }

    return Number.MAX_VALUE;
  }

  blockWrap() {
    return this.trial().plotDesign() === 'rcb' && this.trial().rcbType() === 'rcb_horizontal';
  }

  private plotsPerReplication(): number {
    return this.testSubjects().reduce((acc, factor) => Math.max(1, factor.limitTo().length) * acc, 1);
  }

  private hasSplitPlotBlocksPerRow(): boolean {
    return parseInt(this.spRepBlocksPerRow(), 10) > 0;
  }

  async applyCustomLayout(data: CustomLayoutData): Promise<string[]> {
    const trial = this.trial();
    let errors: string[] = [];

    if (!trial.isDraft()) {
      // validate that no dimension or site has been removed

      const presentDimIds = new Set<string>();
      for (let dims of data.ddm_dimensions) {
        for (let dim of dims) {
          presentDimIds.add(dim.id);
        }
      }
      for (let siteId of data.site_ids) {
        presentDimIds.add(siteId);
      }

      for (let ddm of this.testSubjects()) {
        for (let dim of ddm.limitTo()) {
          if (!presentDimIds.has(dim.id())) {
            errors.push(
              i18n.t("The trial is not a draft. You can't remove {{dim}}.", {
                dim: translate(dim.nameJson()),
              })()
            );
          }
        }
      }
      for (let site of this.sites()) {
        if (!presentDimIds.has(site.id())) {
          errors.push(
            i18n.t("The trial is not a draft. You can't remove {{dim}}.", {
              dim: translate(site.nameJson()),
            })()
          );
        }
      }
    }

    if (errors.length > 0) {
      return errors;
    }

    const sites = data.site_ids.length > 0 ? await sitesApi.listIds(data.site_ids) : [];

    if (CUSTOM_LAYOUT_VALID_PLOT_DESIGNS.indexOf(trial.plotDesign()) === -1) {
      // custom layouts are only valid for a few type of layouts
      trial.plotDesign(CUSTOM_LAYOUT_VALID_PLOT_DESIGNS[0]);
      this.customerDimensions([]);
      this.spRepBlocksPerRow('');
      this.alBlockSize('');
      this.alReplicationsPerRow('');
      this.alBlocksPerReplicationRow('');
    }

    this.treatments(data.treatments.map((treatmentData) => new Treatment(treatmentData)));

    let i = 0;
    for (let ddm of this.testSubjects()) {
      ddm.limitTo(
        data.ddm_dimensions[i].map((dimData) => new LimitToDimension(this, ddm.dimensionMeta(), dimData))
      );
      i++;
    }

    this.sites(sites.map((site) => new LimitToDimension(this, this.siteDM, site)));
    this.replications(data.replications);
    this.plotGuides(data.plot_guides);
    this.savePlotGuides = true;
    this.trial().rowLength('1');
    this.forceRegeneratePlots('full');

    return [];
  }
}

const defaultResolutionIndex = 1; // medium resolution

// NOTE: must be kept in sync with Dataset.SUPPORTED_RESOLUTIONS on the backend
const supportedResolutions = [
  { id: '1', name: i18n.t('Small')() + ' (320x240)', width: 320, height: 240 },
  { id: '2', name: i18n.t('Medium')() + ' (640x480)', width: 640, height: 480 },
  { id: '3', name: i18n.t('Large')() + ' (800x600)', width: 800, height: 600 },
  {
    id: '4',
    name: i18n.t('Extra Large')() + ' (1600x1200)',
    width: 1600,
    height: 1200,
  },
  { id: '5', name: i18n.t('Full size')(), width: 1601, height: 1200 },
];

function getResolutionId(data: { image_resolution_width: number; image_resolution_height: number }): string {
  for (let resolution of supportedResolutions) {
    if (
      resolution.width == data.image_resolution_width &&
      resolution.height == data.image_resolution_height
    ) {
      return resolution.id;
    }
  }

  return null;
}

function toResolutionData(id: string): {
  image_resolution_width: number;
  image_resolution_height: number;
} {
  let resolution = supportedResolutions[defaultResolutionIndex];

  for (const res of supportedResolutions) {
    if (res.id == id) {
      resolution = res;
    }
  }

  return {
    image_resolution_width: resolution.width,
    image_resolution_height: resolution.height,
  };
}

export class DatasetWizard {
  private slugGenerator: SlugGenerator;

  supportedResolutions = supportedResolutions;
  resolutionId = ko.observable(supportedResolutions[defaultResolutionIndex].id);

  id = ko.observable<string>(null);
  private copiedFromId: string = null;
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  order = ko.observable(1);
  skipDimensionSelection = ko.observable(false);
  forceSiteSelection = ko.observable(false);
  containsManagementData = ko.observable(false);
  canAddFact = ko.observable(false);
  nameSlug = ko.observable('').extend(slugValidation);
  measurementMetas = ko.observableArray<MeasurementMeta>();
  requiredDatasetDimensionMetas = ko.observableArray<DatasetDimensionMeta>();

  plotDMSelections = ko.observableArray<DimensionMetaSelection>();
  orderedMeasurementMetas = new OrderedEntities(this.measurementMetas);
  errors = ko.validation.group(this, { deep: false });

  otherDDMConfig: DatasetDimensionMetasEditConfiguration;

  // mms minus legacy free ddm
  properMeasurementMetas = ko.pureComputed(() => this.measurementMetas().filter((mm) => !mm.isDDMEntity));

  private preferEmbeddedMT = session.tenant().prefer_embedded_mt;

  private subscriptions: KnockoutSubscription[] = [];

  constructor(private trialWizard: TrialWizard, data?: DatasetWizardData, defaultName?: string) {
    let trial = trialWizard.trial();
    let allowEditAny = canEditTrial(trialWizard.userData, trial);

    this.otherDDMConfig = {
      user: this.trialWizard.userData,

      addDMText: i18n.t('Add entity'),
      selectDMText: i18n.t('Select'),
      emptyDDMWarning: '',
      emptyDimensionsWarning: '',
      addDimensionText(ddm: DatasetDimensionMeta) {
        return i18n.t('Add selectable record')();
      },

      allowAnonymize: true,
      allowEditOptional: true,
      trialWizard: trialWizard,
      canEditRequired: () => false,
      canEditDimensionMeta: (ddm: DatasetDimensionMeta) => true,
      allowAdd: () => allowEditAny,
      allowEdit: ko.pureComputed(() => {
        let trial = this.trialWizard.trial();
        return allowEditAny && (!this.id() || trial.isDraft());
      }),
      allowEditAny: ko.pureComputed(() => {
        return allowEditAny;
      }),
      allowRemove: (ddm: DatasetDimensionMeta) => {
        return !ddm.id() || this.trialWizard.trial().isDraft();
      },
      rankingDependsOnDDM(ddm: DatasetDimensionMeta) {
        return false;
      },

      datasetDimensionMetas: this.requiredDatasetDimensionMetas,
      createDatasetDimensionMeta: () => {
        return new DatasetDimensionMeta(this.trialWizard);
      },

      confirmEditEntity: () => {
        return Promise.resolve(null);
      },
      confirmChangeEntities: () => {
        return Promise.resolve(null);
      },
      allowReorder: allowEditAny,
      allowViewLimitToDimensionNameAndAttributes: allowViewLimitToDimensionNameAndAttributes(),
    };

    if (data) {
      let trial = trialWizard.trial();

      this.resolutionId(getResolutionId(data));

      this.id(data.id);
      this.copiedFromId = data.copied_from_id;
      this.nameJson(data.name_json);
      this.order(data.order);
      this.skipDimensionSelection(data.skip_dimension_selection);
      this.forceSiteSelection(data.force_site_selection);
      this.containsManagementData(data.contains_management_data);
      this.canAddFact(data.can_add_fact);
      this.nameSlug(data.name_slug);

      let order = 0;
      let mms = data.measurement_dataset_dimension_metas.map((d) => {
        let mm = MeasurementMeta.fromDDM(trial, new DatasetDimensionMeta(trialWizard, d));
        mm.order(order);
        order++;

        return mm;
      });
      this.measurementMetas(
        mms.concat(
          data.measurement_metas.map((d) => {
            let mm = new MeasurementMeta(
              {
                allowDimensionMeta: true,
                allowRanking: true,
                management: false,
                requireMeasurementType: !this.preferEmbeddedMT,
                validateLimitTo: this.mmLimitToRequired(),
              },
              trial,
              d
            );
            mm.order(mm.order() + order);
            return mm;
          })
        )
      );
      this.orderedMeasurementMetas = new OrderedEntities(this.measurementMetas);
      this.requiredDatasetDimensionMetas(
        data.required_dataset_dimension_metas.map((d) => new DatasetDimensionMeta(trialWizard, d))
      );
      this.updatePlotDDMSelections(data.excluded_plot_dm_ids);
    } else {
      this.nameJson(asI18nText(defaultName || i18n.t('Trait group')()));
      this.updatePlotDDMSelections([]);
    }

    this.subscriptions.push(this.nameJson.subscribe(this.onNameChanged));
    this.subscriptions.push(
      trialWizard.testSubjects.subscribe(() => {
        this.updatePlotDDMSelections();
      })
    );
    this.subscriptions.push(
      trialWizard.sites.subscribe(() => {
        this.updatePlotDDMSelections();
      })
    );

    this.subscriptions.push(
      this.measurementMetas.subscribe(() => {
        this.trialWizard.checkTPPTraits();
      })
    );

    this.slugGenerator = new SlugGenerator(this.nameJson, null, this.nameSlug, {
      canEdit: !this.id() || this.trialWizard.trial().isDraft(),
      fillIfEmpty: true,
    });
  }

  dipose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
    this.slugGenerator.dispose();
  }

  nameOrError = ko.pureComputed(() => {
    let name = this.nameJson();
    if (!translate(name).trim()) {
      return i18n.t('Missing group name')();
    } else {
      return name;
    }
  });

  getValidationTarget(): {} {
    let target = ko.utils.extend({}, this);

    // avoid recursion, which would ignore getValidationTarget() for the wizard
    delete (<any>target)['trialWizard'];

    return target;
  }

  addEmptyMeasurementMeta() {
    return this.addMeasurementMeta(undefined);
  }

  addMeasurementMeta(measurementMetaData: datasetsApi.MeasurementMetaData) {
    let measurementMeta = new MeasurementMeta(
      {
        allowDimensionMeta: true,
        allowRanking: true,
        management: false,
        requireMeasurementType: !this.preferEmbeddedMT,
        validateLimitTo: this.mmLimitToRequired(),
      },
      this.trialWizard.trial(),
      measurementMetaData
    );
    if (this.hasExcludedPlotDM()) {
      // default to false when this is not a plot dataset
      measurementMeta.includeInSummary(false);
    }
    this.orderedMeasurementMetas.add(measurementMeta);

    return measurementMeta;
  }

  private mmLimitToRequired() {
    return APP_CONFIG.MM_LIMIT_TO_REQUIRED && !this.trialWizard.trial().template;
  }

  private onNameChanged = () => {
    this.nameJson.serverError('');
  };

  private updatePlotDDMSelections(initialValue?: string[]) {
    if (!initialValue) {
      initialValue = this.getExcludePlotDMIds();
    }

    this.plotDMSelections(
      this.trialWizard.testSubjects().map((ddm) => {
        return new DimensionMetaSelection(ddm.dimensionMeta());
      })
    );
    if (this.trialWizard.sites().length > 0) {
      this.plotDMSelections.push(new DimensionMetaSelection(this.trialWizard.siteDM));
    }
    this.plotDMSelections.push(new DimensionMetaSelection(this.trialWizard.replicationDM));

    for (let sel of this.plotDMSelections()) {
      if (sel.dimensionMeta && initialValue.indexOf(sel.dimensionMeta.id()) !== -1) {
        sel.include(false);
      }
    }
  }

  hasExcludedPlotDM(): boolean {
    return this.getExcludePlotDMIds().length > 0;
  }

  private getExcludePlotDMIds(): string[] {
    return this.plotDMSelections()
      .filter((sel) => !sel.include())
      .map((sel) => sel.dimensionMeta.id());
  }

  hasSites = ko.pureComputed(() => {
    if (this.trialWizard.sites().length === 0) {
      return false;
    }

    for (let sel of this.plotDMSelections()) {
      if (sel.dimensionMeta && sel.dimensionMeta.slug() === SITE_SLUG && !sel.include()) {
        return false;
      }
    }

    return true;
  });

  hasErrors() {
    if (this.errors().length > 0 || this.nameJson.serverError() || this.nameSlug.serverError()) {
      return true;
    }

    for (let ddm of this.requiredDatasetDimensionMetas()) {
      if (ddm.hasErrors()) {
        return true;
      }
    }

    for (let mm of this.measurementMetas()) {
      if (mm.hasErrors()) {
        return true;
      }
    }

    return false;
  }

  showErrors() {
    this.errors.showAllMessages();

    for (let ddm of this.requiredDatasetDimensionMetas()) {
      ddm.showErrors();
    }

    for (let mm of this.measurementMetas()) {
      mm.showErrors();
    }
  }

  toData(isMasterDataset: boolean): DatasetWizardData {
    let order = 0;
    for (let ddm of this.requiredDatasetDimensionMetas()) {
      ddm.required(true);
      ddm.useForPlot(false);
      ddm.order(order);
      order++;
    }

    return {
      id: this.id(),
      copied_from_id: this.copiedFromId,
      name_json: this.nameJson(),
      order: this.order(),
      skip_dimension_selection: this.skipDimensionSelection(),
      force_site_selection: this.forceSiteSelection(),
      contains_management_data: this.containsManagementData(),
      can_add_fact: this.canAddFact(),
      name_slug: this.nameSlug(),
      is_master_dataset: isMasterDataset,
      measurement_dataset_dimension_metas: this.measurementMetas()
        .filter((mm) => mm.isDDMEntity)
        .map((mm) => mm.toDatasetDimensionMetaData()),
      measurement_metas: this.measurementMetas()
        .filter((mm) => !mm.isDDMEntity)
        .map((meta) => meta.toData()),
      required_dataset_dimension_metas: this.requiredDatasetDimensionMetas().map((ddm) => ddm.toData(true)),
      excluded_plot_dm_ids: this.getExcludePlotDMIds(),
      ...toResolutionData(this.resolutionId()),
    };
  }
}

class DimensionMetaSelection {
  include = ko.observable(true);

  nameJson = ko.pureComputed(() => {
    if (!this.dimensionMeta) {
      return undefined;
    }

    return this.dimensionMeta.nameJson();
  });

  constructor(public dimensionMeta: DimensionMeta) {}
}

interface ScheduledVisitForTimeline {
  estDaysAfterPlanting(): string;
  estDaysAfterDateTrait(): string;
  popupTitle: ko.Subscribable<string> | string;
  groupedObservations: ko.Subscribable<GroupedObservations> | GroupedObservations;
  hasErrors(): boolean;
  showErrors(): void;
}

type GroupedObservations = {
  nameJson: I18nText;
  observations: ScheduledVisitTraitData[];
}[];

interface ScheduledVisitObservation {
  id: string;
  server_id: string | null;
  ds: DatasetWizard;
  mm: MeasurementMeta;
}

export class ScheduledVisit
  extends ScheduledVisitBase<ScheduledVisitObservation>
  implements ScheduledVisitForTimeline
{
  private nextTempId = 0;
  private tempIds = new Map<MeasurementMeta, string>();
  baseDate = ko.observable<ScheduledVisitObservation>().extend({
    validation: {
      validator: (obs: ScheduledVisitObservation) => {
        return !obs || obs.mm.isDate();
      },
      message: i18n.t('The selected observation must be a date')(),
    },
  });
  observations = ko.observableArray<ScheduledVisitObservation>().extend({ required: true });
  onObservationsChange = () => {
    this.observationsListener.dispose();
    this.observations.sort((a, b) => a.ds.order() - b.ds.order() || a.mm.order() - b.mm.order());
    this.observationsListener = this.observations.subscribe(this.onObservationsChange);
  };
  observationsListener = this.observations.subscribe(this.onObservationsChange);

  mixedSites = ko.pureComputed<boolean>(() => {
    let hasSites: null | true | false = null;
    for (let item of this.observations()) {
      let itemHasSites = item.ds.hasSites();
      if (hasSites === null) {
        hasSites = itemHasSites;
      } else if (hasSites !== itemHasSites) {
        return true;
      }
    }

    return false;
  });

  errors = ko.validation.group(
    [this.nameJson, this.code, this.baseDate, this.days, this.daysAfterPlanting, this.observations],
    { deep: false }
  );

  datasetIndex = ko.observable('0');
  datasetOptions = ko.pureComputed(() => {
    return this.datasets().map((ds, idx) => ({
      idx: idx.toString(),
      name: translate(ds.nameJson()),
      ds,
    }));
  });

  observationsSearchConfig: FormSelectSearchConfiguration<ScheduledVisitObservation> = {
    enableAddAll: true,
    list: (params) =>
      this.selectableObservations(
        params,
        (dsIdx, mm) => dsIdx === this.datasetIndex() && mm.type() !== 'ranking'
      ),
    entities: this.observations,
    getSummaryName: (item) => translate(item.ds.nameJson()) + ' - ' + translate(item.mm.nameJson()),
    getSelectedEntityClass: (item) => (item.mm.isDerived() ? 'derived-scheduled-observation' : ''),
  };

  constructor(public datasets: ko.ObservableArray<DatasetWizard>, data?: ScheduledVisitData) {
    super();
    this.finishSetup(
      {
        list: (params) => this.selectableObservations(params, (dsIdx, mm) => mm.type() === 'date'),
        entity: this.baseDate,
        getSummaryName: (item) => translate(item.ds.nameJson()) + ' - ' + translate(item.mm.nameJson()),
      },
      data
    );

    if (data) {
      let mms: {
        [key: string]: { id: string; ds: DatasetWizard; mm: MeasurementMeta };
      } = {};
      for (let ds of datasets()) {
        for (let mm of ds.properMeasurementMetas()) {
          let id = this.tempIdFor(mm);
          mms[mm.id() || mm.copiedFromId] = { id, ds, mm };
        }
      }

      if (data.base_date_id) {
        this.baseDate({ server_id: null, ...mms[data.base_date_id] });
      } else {
        this.baseDate(null);
      }
      let obs = data.observations.slice();
      obs.sort((a, b) => a.order - b.order);
      this.observations(
        obs.map((obs) => ({
          server_id: obs.id,
          ...mms[obs.measurement_meta_id],
        }))
      );
    }
  }

  private tempIdFor(mm: MeasurementMeta) {
    if (!this.tempIds.has(mm)) {
      this.nextTempId++;
      this.tempIds.set(mm, this.nextTempId.toString());
    }

    return this.tempIds.get(mm);
  }

  private selectableObservations(
    params: ListRequestParams,
    filter: (dsIdx: string, mm: MeasurementMeta) => boolean
  ): Promise<ScheduledVisitObservation[]> {
    let items: ScheduledVisitObservation[] = [];
    for (let opt of this.datasetOptions()) {
      for (let mm of opt.ds.properMeasurementMetas()) {
        if (
          filter(opt.idx, mm) &&
          (!params.name_prefix ||
            translate(mm.nameJson()).toLocaleLowerCase().indexOf(params.name_prefix.toLocaleLowerCase()) >
              -1)
        ) {
          let id = this.tempIdFor(mm);
          items.push({ id, server_id: null, ds: opt.ds, mm });
        }
      }
    }

    let offset = params.offset || 0;
    return Promise.resolve(items.slice(offset, offset + params.limit));
  }

  onDatasetRemoved(ds: DatasetWizard) {
    if (this.datasetOptions().every((ds) => ds.idx !== this.datasetIndex())) {
      this.datasetIndex('0');
    }
    if (this.baseDate()?.ds === ds) {
      this.baseDate(null);
    }
    this.observations(this.observations().filter((obs) => obs.ds !== ds));
  }

  onMeasurementMetaRemoved(mm: MeasurementMeta) {
    if (this.baseDate()?.mm === mm) {
      this.baseDate(null);
    }
    this.observations(this.observations().filter((obs) => obs.mm !== mm));
  }

  toData(order: number): ScheduledVisitData {
    let mms = new Map<MeasurementMeta, number>();
    let idx = 0;
    for (let ds of this.datasets()) {
      for (let mm of ds.properMeasurementMetas()) {
        mms.set(mm, idx++);
      }
    }

    return {
      ...super.toData(order),
      base_date_idx: mms.get(this.baseDate()?.mm) ?? null,
      observations: this.observations().map((obs, idx) => ({
        id: obs.server_id,
        measurement_meta_idx: mms.get(obs.mm),
        order: idx,
      })),
    };
  }

  hasErrors() {
    return this.errors().length > 0 || this.mixedSites() || !!this.code.serverError();
  }

  showErrors() {
    this.errors.showAllMessages();
  }

  groupedObservations = ko.pureComputed(() => {
    let grouped: { [key: string]: ScheduledVisitObservation[] } = groupBy(
      this.observations(),
      (obs) => obs.ds.id(),
      accList
    );

    return this.datasets()
      .filter((ds) => !!grouped[ds.id()])
      .map((ds) => ({
        nameJson: ds.nameJson(),
        observations: grouped[ds.id()].map((obs) => ({
          mm_id: obs.mm.id(),
          name: obs.mm.nameJson(),
          order: obs.mm.order(),
          ds_id: ds.id(),
          ds_name: ds.nameJson(),
        })),
      }));
  });

  popupTitle = ko.pureComputed(() => {
    return translate(this.nameJson()) || '—';
  });
}

class DSVEdit {
  hasBaseDate = ko.observable(false);
  days = ko.observable('').extend({
    required: { onlyIf: () => this.hasBaseDate() },
    ...INTEGER_VALIDATION_RULES,
  });
  daysAfterPlanting = ko.observable('').extend(INTEGER_VALIDATION_RULES);
  showConfirmDays = APP_CONFIG.CONFIRM_SV_DAYS;
  confirmDays = ko.observable(!APP_CONFIG.CONFIRM_SV_DAYS).extend({
    validation: {
      validator: (val) => val,
      message: i18n.t('Confirmation required.')(),
    },
  });

  errors = ko.validation.group([this.days, this.daysAfterPlanting, this.confirmDays]);
  disabled = ko.observable(false);
  constructor(data: ScheduledVisitDaysData) {
    if (data) {
      this.days(data.days_offset?.toString() ?? '');
      this.daysAfterPlanting(data.days_after_planting?.toString() || '');
      this.disabled(data.disabled);
    }
  }

  setPreview(previewData: ScheduledVisitPreviewData) {
    this.hasBaseDate(!!previewData.base_date_observation);
    if (!previewData.is_new) {
      this.confirmDays(true);
    }
  }

  estDaysAfterPlanting(): string {
    return this.daysAfterPlanting();
  }

  disable(): void {
    this.disabled(!this.disabled());
  }

  toData() {
    const days = parseInt(this.days(), 10);
    const daysAfterPlanting = parseInt(this.daysAfterPlanting(), 10);

    return {
      days_offset: isNaN(days) ? null : days,
      days_after_planting: isNaN(daysAfterPlanting) ? null : daysAfterPlanting,
      disabled: this.disabled(),
    };
  }
}

export class DerivedScheduledVisit implements ScheduledVisitForTimeline {
  id: string;
  nameJson: I18nText;
  code: string;
  oneMinVisit: boolean;
  nMaxVisits: number;
  repetitionNumberOfDays: number;
  maxNumberOfRepetition: number;
  popupTitle: string;
  daysRelativeTo: string;
  groupedObservations: GroupedObservations = [];

  constructor(data: ScheduledVisitPreviewData, public edit: DSVEdit) {
    this.id = data.id;
    this.nameJson = data.name_json;
    this.code = data.code;
    this.oneMinVisit = data.n_min_visits > 0;
    this.nMaxVisits = data.n_max_visits;
    this.repetitionNumberOfDays = data.repetition_number_of_days;
    this.maxNumberOfRepetition = data.maximum_number_of_repetitions;
    this.popupTitle = translate(this.nameJson);
    this.daysRelativeTo =
      translate(data.base_date_observation) || i18n.t(['planting_date_title', 'Planting/reference date'])();

    let groupedObservations = [];
    let grouped: { [key: string]: ScheduledVisitTraitData[] } = groupBy(
      data.observations,
      (obs) => obs.ds_id,
      accList
    );
    for (const ds in grouped) {
      groupedObservations.push({
        nameJson: grouped[ds][0].ds_name,
        observations: grouped[ds],
      });
    }

    this.groupedObservations = groupedObservations;
  }

  estDaysAfterPlanting(): string {
    return this.edit.estDaysAfterPlanting();
  }

  estDaysAfterDateTrait(): string {
    return this.edit.days();
  }

  disabled(): boolean {
    return this.edit.disabled();
  }

  disable(): void {
    this.edit.disable();
  }

  hasErrors(): boolean {
    return this.edit.errors().length > 0;
  }

  showErrors() {
    this.edit.errors.showAllMessages();
  }

  toData(): ScheduledVisitDaysData {
    return {
      sv_id: this.id,
      ...this.edit.toData(),
    };
  }
}

class TrialTraits {
  loaded = ko.observable(false);
  selectedTraits = ko.observableArray<SelectedTrait>();
  needsLoadLibrarySV = false; // tells if the svs need to be loaded to validate the visits
  drake: dragula.Drake[] = [];

  // keep errors in a separate map, so they're preserved
  // when reloading the traits
  // mm id -> error
  private errors = new Map<string, ko.Observable<string>>();

  constructor(private initialTraits: TrialTraitData[]) {}

  async load() {
    if (this.loaded()) {
      return;
    }

    this.selectedTraits([]);

    const selectedTraits: SelectedTrait[] = [];

    if (this.initialTraits.length > 0) {
      const mms = await listTrialLibrary({
        mm_ids: this.initialTraits.map((t) => t.mm_id),
      });
      const byId = new Map<string, SelectedTraitData>();
      for (const mm of mms) {
        byId.set(mm.id, mm);
      }

      for (const trait of this.initialTraits) {
        const mm = byId.get(trait.mm_id);
        if (mm) {
          // traits won't be saved if this trial is created from a template and not saved yet.
          const isSaved = trait.mm_id !== trait.mm_template_id;
          selectedTraits.push(selectedTraitFromMM(mm, isSaved));
          if (!this.errors.has(trait.mm_id)) {
            this.errors.set(trait.mm_id, ko.observable(''));
          }
        }
      }
    }

    this.selectedTraits(selectedTraits);
    this.loaded(true);
    this.initDragAndDrop();
  }
  initDragAndDrop = () => {
    waitForElement('#selected-traits-body').then(() => {
      if (this.drake.length > 0) {
        for(let drake of this.drake) {
          drake.destroy();
        }
        this.drake = [];
      }

      for(let dragulaRoot of Array.from(document.querySelectorAll('#selected-traits-body'))) {
        let drake = dragula([dragulaRoot], {
          revertOnSpill: true,
          moves: (el, container, handle) => {
            return handle.classList.contains('trait-drag-indicator-handle');
          },
        });

        const onDrop = (el: HTMLTableRowElement, source: HTMLTableRowElement, target: HTMLTableRowElement, sibling: HTMLTableElement) => {
          const traitId = el.getAttribute('selected-trait-id');
          const siblingTraitId = sibling?.getAttribute('selected-trait-id');

          const oldIndex = this.selectedTraits().findIndex((t) => t.mmId === traitId);
          const siblingIndex = this.selectedTraits().findIndex((t) => t.mmId === siblingTraitId);
          const lastPosition = this.selectedTraits().length - 1;
          let newIndex;

          // The "sibling" element represents the next element after the drop target. This leads to three possible cases:
          // 1. The element has moved up the list, then the new index becomes the sibling's index
          // 2. The element has moved down the list, then the new index becomes the sibling's index - 1
          // 3. The element has moved to the last position, then the new index becomes the last position
          if(siblingIndex != -1) {
            newIndex = oldIndex < siblingIndex ? siblingIndex - 1 : siblingIndex;
          } else {
            newIndex = lastPosition;
          }

          const copyOfSelectedTraits = this.selectedTraits().slice();
          const elementToRemove = copyOfSelectedTraits.splice(
            this.selectedTraits().findIndex((t) => t.mmId === traitId),
            1
          )[0];
          copyOfSelectedTraits.splice(newIndex, 0, elementToRemove);
          this.selectedTraits(copyOfSelectedTraits);
        };

        drake.on('drag', (el: HTMLTableRowElement) => {
          el.classList.add('selected-traits-table-dragging');
        });
        drake.on('dragend', (el: HTMLTableRowElement) => {
          el.classList.remove('selected-traits-table-dragging');
        });
        drake.on('drop', onDrop);

        this.drake.push(drake);
      }
    });
  }

  applyServerErrors(errors: any) {
    const traitErrors = errors?.['traits'];
    if (traitErrors) {
      this.selectedTraits().forEach((t, i) => {
        this.errors.get(t.mmId)(traitErrors[i] ?? '');
      });
    }
  }

  traitError(trait: SelectedTrait): string {
    return this.errors.get(trait.mmId)();
  }

  hasErrors(): boolean {
    return this.selectedTraits().some((t) => !!this.errors.get(t.mmId)());
  }

  remove = (trait: SelectedTrait) => {
    this.selectedTraits.remove(trait);
    this.needsLoadLibrarySV = true;
    this.errors.set(trait.mmId, ko.observable(''));
  };

  edit = (trait: SelectedTrait) => {
    openTrialTraitEdit(trait.mmId);

    // Given that an edited trait might have its scheduled visits removed,
    // we need to reload the scheduled visits
    this.needsLoadLibrarySV = true;
  };

  add(selected: datasetsApi.MeasurementMetaData[]) {
    const validatedMeasurementMetas = [];
    for (let mm of selected) {
      if (!this.errors.has(mm.id)) {
        this.errors.set(mm.id, ko.observable(''));
      }
      validatedMeasurementMetas.push(selectedTraitFromMM(mm, false));
      this.needsLoadLibrarySV = true;
    }
    this.selectedTraits.push(...validatedMeasurementMetas);
  }

  toData(): TrialTraitData[] {
    if (this.loaded()) {
      return this.selectedTraits().map((t, i) => ({ mm_id: t.mmId, order: i }));
    } else {
      return this.initialTraits.map((t) => ({
        mm_id: t.mm_id,
        order: t.order,
      }));
    }
  }
}

export interface SelectedTrait {
  isSaved: boolean;
  mmId: string;
  templateId: string;
  name: I18nText;
  nameSlug: string;
  traitCategoryName: I18nText;
  traitCategoryId: string;
  dataCollectionDetails: string;
  typeName: string;
  type: string;
  tags: string;
  tagsIds: string[];
  scheduledVisitsIds?: string[];
}

function selectedTraitFromMM(mm: SelectedTraitData, isSaved: boolean): SelectedTrait {
  return {
    isSaved,
    mmId: mm.id,
    templateId: mm.template_id,
    name: mm.name_json,
    nameSlug: mm.name_slug,
    traitCategoryName: mm.trait_category?.name_json,
    traitCategoryId: mm.trait_category?.id,
    dataCollectionDetails: mm.data_collection_details ?? '',
    type: mm.type,
    typeName: typeName(mm),
    tags: mm.tags?.map((tag) => translate(tag.name_json))?.join(', ') ?? '',
    tagsIds: mm.tags?.map((tag) => tag.id) ?? [],
    scheduledVisitsIds: mm.scheduled_visits.map((sv) => sv.template ? sv.template.toString() : sv.id)
  };
}
