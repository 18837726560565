import * as ko from 'knockout';
import i18n from '../../i18n';

import { BaseTrialStep } from './base';
import { I18nText, translate } from '../../i18n_text';
import { pad, delay } from '../../utils';
import { WizardController } from '../../screens/trial_wizard';
import { confirmDialog } from '../confirm_dialog';
import { RCBType } from '../../api/datasets';
import { PreviewView } from './plots_preview/plots_preview';
import { PlotsPreviewModel } from './plots_preview/plots_preview_model';
import { sortBy } from 'lodash';
import { PlotNaming, RangeDirection } from '../../api/trials';
import { CUSTOM_LAYOUT_VALID_PLOT_DESIGNS } from '../../models/trial';
import { session } from '../../session';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/trial_experimental_design.html').default;

interface PlotLayout {
  name: string;
  value: string;
}

let replications: number[] = [];
for (let i = 1; i <= 999; i++) {
  replications.push(i);
}

class TrialExperimentalDesign extends BaseTrialStep {
  private oldDesign: string;

  private subscriptions: KnockoutSubscription[] = [];
  // @ts-ignore
  private title =
    session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
      ? i18n.t('Step 5 - Select the experimental design')()
      : i18n.t('Step 4 - Select the experimental design')();
  keepSeparatedText =
    (session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial()))
      ? i18n.t('Keep treatment factors separated')()
      : i18n.t('Keep test subjects separated')();
  selectForSplitText =
    (session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial()))
      ? i18n.t('Select the treatment factors to use to split the plots:')
      : i18n.t('Select the test subject to use to split the plots:');

  replicationOptions = replications.map((i) => {
    if (i === 1) {
      return { name: i18n.t('No replications')(), value: i };
    } else {
      return { name: pad(i) + ' ' + i18n.t('replications')(), value: i };
    }
  });

  trialPlotDesigns = ko.pureComputed<PlotLayout[]>(() => {
    let options = [
      {
        name: i18n.t('Randomized complete block')(),
        value: 'rcb',
      },
      {
        name: i18n.t('Completely randomized')(),
        value: 'crd',
      },
      {
        name: i18n.t('Latin square')(),
        value: 'latin_square',
      },
      {
        name: i18n.t('Strip')(),
        value: 'default',
      },
      {
        name: i18n.t('Customer')(),
        value: 'customer',
      },
      {
        name: i18n.t('Alpha lattice')(),
        value: 'alpha_lattice',
      },
      {
        name: i18n.t('Manual')(),
        value: 'manual',
      },
    ];

    // The Split plot design is only available when there are more than one test subject and
    // if full factorial was used
    const fullFactorialCombinationsCount = this.trialWizard()
      .testSubjects()
      .map((ts) => ts.limitTo().length)
      .reduce((acc, cur) => acc * cur, 1);
    const isFullFactorialUsed =
      (session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial()) &&
        this.trialWizard().treatments().length === fullFactorialCombinationsCount) ||
      !session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial());
    if (this.trialWizard().testSubjects().length > 1 && isFullFactorialUsed) {
      options.push({
        name: i18n.t('Split plot')(),
        value: 'split_plot',
      });
    }

    // keep showing rcb if it was already selected (for old trials before this change)
    if (this.trialWizard().trial().plotDesign() !== 'rcb' && this.trialWizard().replications() <= 1) {
      options.splice(0, 1);
    }

    if (this.trialWizard().plotGuides()) {
      options = options.filter((opt) => CUSTOM_LAYOUT_VALID_PLOT_DESIGNS.indexOf(opt.value) !== -1);
    }

    return sortBy(options, 'name');
  });

  rcbTypeOptions: { name: string; value: RCBType }[] = [
    { name: i18n.t('Vertical blocks')(), value: 'rcb_vertical' },
    { name: i18n.t('Horizontal blocks')(), value: 'rcb_horizontal' },
    {
      name: i18n.t('Rectangular blocks, laid out horizontally')(),
      value: 'rcb_rect',
    },
  ];

  plotNamingOptions: { name: string; value: PlotNaming }[] = [
    { name: i18n.t('Default')(), value: 'default' },
    { name: i18n.t('Walking order')(), value: 'walking_order' },
    { name: i18n.t('Plot coordinates')(), value: 'coordinates' },
    {
      name: i18n.t('Sequential in replication (left-to-right, top-to-bottom)')(),
      value: 'sequential_in_replication',
    },
    {
      name: i18n.t('Repetition with test subjects order')(),
      value: 'repetition_and_order',
    },
  ];

  rangeDirectionOptions: { name: string; value: RangeDirection }[] = [
    { name: i18n.t('Top-down')(), value: 'top_down' },
    { name: i18n.t('Bottom-up')(), value: 'bottom_up' },
  ];

  loadingPreview = ko.observable(false);
  tooManyPlots = ko.observable(false);
  private previewRequestNumber = 0;
  preview = new PreviewView();
  previewTitle = ko.pureComputed(() => {
    let title = i18n.t('Preview')();
    if (this.trialWizard().sites().length > 0) {
      const firstSite = this.trialWizard().sites()[0];
      title = `${title} ${translate(firstSite.nameJson())}`;
    }
    return title;
  });

  constructor(params: { controller: WizardController }) {
    super(params);

    let superAllowEditAny = this.allowEditAny;
    this.allowEditAny = ko.pureComputed(() => {
      if (!this.trialWizard().canSafelyRegeneratePlots()) {
        return false;
      }

      return superAllowEditAny();
    });

    this.reload();
  }

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }

    this.subscriptions = [];
  }

  private onReplicationNumberChanged = () => {
    let trial = this.trialWizard().trial();
    if (this.trialWizard().replications() <= 1 && trial.plotDesign() === 'rcb') {
      trial.plotDesign('default');
    }
    this.markGeneratePlots();
  };

  reload() {
    // reload subscriptions, because we might be dealing with different trial wizard
    this.dispose();

    let wizard = this.trialWizard();
    let trial = wizard.trial();

    this.oldDesign = trial.plotDesign();

    this.subscriptions.push(this.trialWizard.subscribe(() => this.reload()));
    this.subscriptions.push(wizard.replications.subscribe(this.onReplicationNumberChanged));

    this.subscriptions.push(trial.plotNaming.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(trial.rangeDirection.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(trial.plotDesign.subscribe(this.onPlotDesignTypeChanged));
    this.subscriptions.push(trial.rcbType.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(trial.rowLength.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(trial.rcbBlockWidth.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(trial.rcbBlocksPerRow.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(trial.separateTestSubjects.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(wizard.spMainDMId.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(wizard.spRepBlocksPerRow.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(wizard.alBlockSize.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(wizard.alReplicationsPerRow.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(wizard.alBlocksPerReplicationRow.subscribe(this.onPlotDesignChanged));
    this.subscriptions.push(wizard.alPlotsPerBlockRow.subscribe(this.onPlotDesignChanged));

    this.loadPreview();

    this.ready(true);
  }

  hasErrors() {
    let wizard = this.trialWizard();
    let trial = wizard.trial();

    return (
      !trial.rowLength.isValid() ||
      !trial.rcbBlockWidth.isValid() ||
      !trial.rcbBlocksPerRow.isValid() ||
      !wizard.replications.isValid() ||
      !wizard.alBlockSize.isValid() ||
      !wizard.alReplicationsPerRow.isValid() ||
      !wizard.alBlocksPerReplicationRow.isValid() ||
      !wizard.alPlotsPerBlockRow.isValid() ||
      !wizard.spMainDMId.isValid() ||
      !wizard.spRepBlocksPerRow.isValid()
    );
  }

  private onPlotDesignTypeChanged = () => {
    this.onPlotDesignChanged();
    if (!this.designChangedReentrant) {
      this.trialWizard().onPlotDesignChanged();
    }
  };

  private designChangedReentrant = false;
  private onPlotDesignChanged = () => {
    if (this.designChangedReentrant) {
      return;
    }
    this.designChangedReentrant = true;

    let wizard = this.trialWizard();
    let trial = wizard.trial();
    let newDesign = wizard.trial().plotDesign();

    if (newDesign === 'customer') {
      wizard.replications(1);
    }
    if (newDesign === 'split_plot' && this.oldDesign !== 'split_plot') {
      wizard.spRepBlocksPerRow('1');
    }
    if (newDesign === 'rcb' && this.oldDesign !== 'rcb') {
      trial.rcbType('rcb_vertical');
    }
    if (newDesign !== 'rcb' || trial.rcbType() !== 'rcb_rect') {
      trial.rcbBlockWidth('');
      trial.rcbBlockWidth.isModified(false);
      trial.rcbBlocksPerRow('');
      trial.rcbBlocksPerRow.isModified(false);
    }
    if (
      newDesign !== 'default' &&
      newDesign !== 'manual' &&
      (newDesign !== 'rcb' || trial.rcbType() !== 'rcb_horizontal')
    ) {
      trial.rowLength('');
      trial.rowLength.isModified(false);
    }
    if (
      newDesign !== this.oldDesign &&
      (newDesign === 'alpha_lattice' || this.oldDesign === 'alpha_lattice')
    ) {
      wizard.alBlockSize('');
      wizard.alBlockSize.isModified(false);
      wizard.alReplicationsPerRow('');
      wizard.alReplicationsPerRow.isModified(false);
      wizard.alBlocksPerReplicationRow('');
      wizard.alBlocksPerReplicationRow.isModified(false);
      wizard.alPlotsPerBlockRow('');
      wizard.alPlotsPerBlockRow.isModified(false);
    }

    this.markGeneratePlots();

    this.oldDesign = newDesign;
    this.designChangedReentrant = false;
  };

  spOptions = ko.pureComputed<{ nameJson: I18nText; value: string }[]>(() => {
    return this.trialWizard()
      .testSubjects()
      .filter((ddm) => ddm.dimensionMeta())
      .map((ddm) => {
        return {
          nameJson: ddm.dimensionMeta().nameJson(),
          value: ddm.dimensionMeta().id(),
        };
      });
  });

  unlockDesign = () => {
    let title = i18n.t('Unlocking the experimental design')();
    let message = i18n.t([
      'unlock_design_warning',
      'You have customized the plots ordering. Unlocking the experimental design will recreate the plots, and some of your customizations might be lost. Are you sure you want to continue?',
    ])();

    confirmDialog(title, message).then(() => this.markGeneratePlots());
  };

  private shouldSkipRegeneratePlots() {
    const newDesign = this.trialWizard().trial().plotDesign();

    // The user wants to import a custom layout, update the experimental design,
    // and keep the order of the imported plots. This shouldn't be a problem since
    // the user still has the option to do a full reset if needed.
    return this.oldDesign === 'manual' && (newDesign === 'rcb' || newDesign === 'default');
  }

  private markGeneratePlots() {
    if (this.shouldSkipRegeneratePlots()) {
      this.trialWizard().forceRegeneratePlots('no');
    } else {
      this.trialWizard().forceRegeneratePlots('full');
    }
    this.loadPreview();
  }

  private async loadPreview() {
    await delay(0); // give time to all validation rules to run
    if (this.hasErrors()) {
      return;
    }

    this.loadingPreview(true);
    this.tooManyPlots(false);

    const requestNumber = ++this.previewRequestNumber;
    try {
      const previewResult = await this.trialWizard().previewPlotsRequest();

      if (requestNumber === this.previewRequestNumber) {
        if (previewResult.isValid) {
          this.preview.update(new PlotsPreviewModel(previewResult.plots, this.trialWizard()));
        } else if (previewResult.errors && previewResult.errors['too_many_plots']) {
          this.tooManyPlots(true);
        }
      }
    } finally {
      if (requestNumber === this.previewRequestNumber) {
        this.loadingPreview(false);
      }
    }
  }
}

ko.components.register('trial-experimental-design', {
  viewModel: TrialExperimentalDesign,
  template: template,
});
