import * as ko from 'knockout';
import page from 'page';

import { BaseForm } from './base_form';
import { session } from '../session';
import * as tenantsApi from '../api/tenants';
import * as usersApi from '../api/users';
import i18n from '../i18n';
import { TrialSelectData } from '../api/base_trials';
import { getTrialSearchConfig } from '../components/configs/search_configs';
import { ImageUpload } from '../image_upload';
import { FileUploadEndpoint } from '../cloud_storage_upload';
import { safeParseDate, serializeDate } from '../api/serialization';

let template = require('raw-loader!../../templates/settings.html').default;

class SettingsScreen extends BaseForm<{}> {
  languageList = SERVER_INFO.LANGUAGE_LIST;
  defaultLanguage = ko.observable(i18n.getUserPreferedLanguage());
  countryAndRegionMandatoryForSite = ko.observable(false);
  allowPasswordLogin = ko.observable(true);
  fullAccessWithEmptyPermissions = ko.observable(true);
  selectiveSyncOption = ko.observable(false);
  adminOnlyDataEditingMode = ko.observable(false);
  defaultTrialTemplate = ko.observable<TrialSelectData>(null);
  isAdmin = ko.observable(false);
  minRequiredAppVersionId = ko.observable<string>('');
  appVersionOptions = ko.observableArray<{ id: string; title: string }>();
  gracePeriodEndDate = ko.observable<Date>().extend({
    //@ts-ignore
    date: true,
    validation: {
      validator: (newDate: Date) => !newDate || newDate >= this.today(),
      message: i18n.t('Grace period end date must be in the future')(),
    },
  });
  webDataEntry = ko.observable(false);

  resettingText = i18n.t('Resetting...')();
  apiKey = ko.observable('');
  resettingAPIKey = ko.observable(false);
  isSelectiveSyncOptionHidden = ![
    'gdm_seeds',
    'gdm_test',
    'demo_13',
    'test',
    'smoke_1',
    'smoke_2',
    'rg_dev',
    'mptest',
    'qt_qa',
  ].includes(session.tenant().slug);

  baseTrialTemplateSearch = getTrialSearchConfig(this.defaultTrialTemplate, {
    template: true,
  });

  private readonly ANY_VERSION_OPTION = { id: '', title: i18n.t('Any')() };

  constructor() {
    super({});

    usersApi.me().then((user) => {
      if (user.role !== 'admin') {
        this.loaded();
        return;
      }

      this.isAdmin(true);
      tenantsApi.options().then((opts) => {
        this.countryAndRegionMandatoryForSite(opts.country_and_region_mandatory_for_site);
        this.allowPasswordLogin(opts.allow_password_login);
        this.fullAccessWithEmptyPermissions(opts.full_access_with_empty_permissions);
        this.selectiveSyncOption(opts.selective_sync_option);
        this.defaultTrialTemplate(opts.default_trial_template);
        this.adminOnlyDataEditingMode(opts.admin_only_data_editing_mode);
        this.apiKey(opts.api_key);
        this.setupAppVersionOptions(opts.app_versions, opts.min_required_app_version_id);
        this.minRequiredAppVersionId(opts.min_required_app_version_id?.toString() ?? '');
        this.gracePeriodEndDate(this.parseGracePeriodEndDate(opts.grace_period_end_date));
        this.webDataEntry(opts.web_data_entry);
        this.logoUpload.picturePublicURL(opts.logo_public_url);
        this.loaded();
      });
    });
  }

  private setupAppVersionOptions(
    appVersions: Array<{ id: number; title: string }>,
    minRequiredVersionId: number | null
  ) {
    const versions = appVersions.map((version) => ({
      id: version.id.toString(),
      title: version.title,
    }));

    const options = minRequiredVersionId === null ? [this.ANY_VERSION_OPTION, ...versions] : versions;

    this.appVersionOptions(options);
  }

  logoUpload = new ImageUpload({
    getImageUploadEndpoint: (contentType: string): Promise<FileUploadEndpoint> => {
      return tenantsApi.getTenantLogoUploadURL(contentType);
    },
  });

  private today() {
    const _today = new Date();
    _today.setHours(0, 0, 0, 0);
    return _today;
  }

  private parseGracePeriodEndDate(value: string | null) {
    if (!value) {
      return null;
    }

    const date = safeParseDate(value);
    if (date && date < this.today()) {
      return null;
    }

    return date;
  }

  save = () => {
    if (this.isAdmin()) {
      let data = {
        country_and_region_mandatory_for_site: this.countryAndRegionMandatoryForSite(),
        allow_password_login: this.allowPasswordLogin(),
        full_access_with_empty_permissions: this.fullAccessWithEmptyPermissions(),
        selective_sync_option: this.selectiveSyncOption(),
        default_trial_template: this.defaultTrialTemplate(),
        min_required_app_version_id: this.minRequiredAppVersionId()
          ? parseInt(this.minRequiredAppVersionId(), 10)
          : null,
        web_data_entry: this.webDataEntry(),
        grace_period_end_date: serializeDate(this.gracePeriodEndDate()),
        admin_only_data_editing_mode: this.adminOnlyDataEditingMode(),
        logo_file_name: this.logoUpload.fileName,
      };
      this.saving(true);
      this.executeSaveRequest(tenantsApi.saveOptions(data)).then(() => {
        session.tenant().country_and_region_mandatory_for_site = data.country_and_region_mandatory_for_site;
        session.tenant().admin_only_data_editing_mode = data.admin_only_data_editing_mode;
        this.updateLanguage();
      });
    } else {
      this.updateLanguage();
    }
  };

  resetAPIKey = () => {
    this.resettingAPIKey(true);
    tenantsApi
      .resetAPIKey()
      .then((res) => {
        this.resettingAPIKey(false);
        this.apiKey(res.api_key);
      })
      .catch(() => {
        this.resettingAPIKey(false);
      });
  };

  private updateLanguage = () => {
    if (this.defaultLanguage() !== i18n.getUserPreferedLanguage()) {
      i18n.setUserPreferedLanguage(this.defaultLanguage());
      page.stop(); // we need the browser to *really* refresh
      location.href = session.toTenantPath('/');
    } else {
      this.close();
    }
  };
}

export let settings = {
  name: 'settings',
  viewModel: SettingsScreen,
  template: template,
};

ko.components.register(settings.name, settings);
